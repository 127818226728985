/* TdhStatsComponent.css */

:root {
    --tdhs-background: #000;
    --tdhs-card-bg: #0a0a0a;
    --tdhs-text-primary: #fff;
    --tdhs-text-secondary: #a0a0a0;
    --tdhs-accent: #ff0000;
    --tdhs-border: #333;
    --tdhs-hover-bg: #111;
    --tdhs-input-bg: #1a1a1a;
}

/* Container */
.tdh-stats-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    background-color: var(--tdhs-background) !important;
    color: var(--tdhs-text-primary) !important;
}

/* Header */
.tdh-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.tdh-title {
    text-align: center;
    color: var(--tdhs-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.tdh-title-accent {
    display: none;
}

/* Last Updated */
.last-updated {
    text-align: center;
    margin-bottom: 20px;
    color: var(--tdhs-text-secondary) !important;
}

/* Chart Section */
.chart-section {
    margin-bottom: 60px;
    padding: 25px;
    background-color: var(--tdhs-card-bg);
    border: 1px solid var(--tdhs-border);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.chart-section-title {
    color: var(--tdhs-text-primary) !important;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--tdhs-border);
}

.chart-section-title.no-border {
    border-bottom: none;
}

.chart-container {
    background-color: var(--tdhs-card-bg);
    padding: 20px;
    border-radius: 8px;
    height: 400px;
}

/* Table Wrapper */
.table-wrapper {
    width: 100%;
    background-color: var(--tdhs-card-bg);
    border: 1px solid var(--tdhs-border);
    border-radius: 8px;
    margin-bottom: 20px;
    overflow-x: auto;
}

/* Table Styles */
.styled-table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--tdhs-card-bg) !important;
}

.styled-table th,
.styled-table td {
    text-align: center;
    padding: 12px;
    border: 1px solid var(--tdhs-border) !important;
    color: var(--tdhs-text-primary) !important;
}

.styled-table th {
    background-color: var(--tdhs-card-bg) !important;
    font-weight: bold;
    white-space: nowrap;
}

.even-row {
    background-color: var(--tdhs-card-bg) !important;
}

.odd-row {
    background-color: var(--tdhs-background) !important;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    color: var(--tdhs-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--tdhs-border);
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--tdhs-card-bg);
}

.pagination li a:hover {
    background-color: var(--tdhs-hover-bg);
}

.pagination .active a {
    background-color: var(--tdhs-accent);
    color: var(--tdhs-text-primary);
}

.pagination .disabled a {
    color: var(--tdhs-text-secondary);
    cursor: not-allowed;
}

/* Filters */
.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--tdhs-card-bg);
    border: 1px solid var(--tdhs-border);
    border-radius: 8px;
}

.filter-section,
.search-section {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.filter-section label,
.search-section label {
    color: var(--tdhs-text-primary);
    white-space: nowrap;
    min-width: 45px;
    text-align: right;
}

.search-section label {
    min-width: 85px;
}

.filter-section select,
.search-section input {
    flex: 1;
    background-color: var(--tdhs-input-bg);
    color: var(--tdhs-text-primary);
    border: 1px solid var(--tdhs-border);
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
}

.search-section input[type="date"] {
    min-width: 150px;
}

/* Table Headers and Sorting */
.sortable {
    cursor: pointer;
    user-select: none;
}

.inline {
    vertical-align: middle;
    margin-left: 4px;
    width: 16px;
    height: 16px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .tdh-stats-container {
        padding: 10px;
    }

    .styled-table {
        font-size: 14px;
    }

    .styled-table th,
    .styled-table td {
        padding: 8px;
    }

    .pagination li a {
        padding: 6px 10px;
        font-size: 14px;
    }

    .filter-container {
        flex-direction: column;
        gap: 15px;
    }

    .filter-section,
    .search-section {
        width: 100%;
    }

    .filter-section select,
    .search-section input {
        width: 100%;
    }
}
  