/* PebblesSalesComponent.css */

:root {
    --psales-background: #000;
    --psales-card-bg: #0a0a0a;
    --psales-text-primary: #fff;
    --psales-text-secondary: #a0a0a0;
    --psales-accent: #ff0000;
    --psales-border: #333;
    --psales-hover-bg: #111;
    --psales-input-bg: #1a1a1a;
}

/* Styles for the Pebbles Sales Component */
.pebbles-sales {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--psales-background);
    color: var(--psales-text-primary);
}

.pebbles-sales .pebbles-sales-title {
    text-align: center;
    color: var(--psales-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.pebbles-sales .pebbles-sales-title a {
    color: var(--psales-accent);
    text-decoration: none;
}

.pebbles-sales .pebbles-sales-title a:hover {
    text-decoration: underline;
}

/* KPI Dashboard */
.pebbles-sales-dashboard {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 30px 0;
    padding: 0 15px;
}

.pebbles-sales-kpi-box {
    flex: 0 1 300px;
    background-color: var(--psales-card-bg);
    border: 1px solid var(--psales-border);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pebbles-sales-kpi-box h3 {
    color: var(--psales-text-secondary);
    font-size: 1em;
    margin: 0 0 10px 0;
    font-weight: normal;
}

.pebbles-sales-kpi-box p {
    color: var(--psales-text-primary);
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
}

.pebbles-sales-kpi-box p.pebbles-sales-kpi-value {
    color: var(--psales-accent);
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
}

.pebbles-sales-kpi-link {
    text-decoration: none;
    display: block;
    transition: color 0.2s ease-in-out;
}

.pebbles-sales-kpi-link h3 {
    color: var(--psales-text-secondary);
    cursor: pointer;
}

.pebbles-sales-kpi-link:hover h3 {
    color: var(--psales-accent);
}

.pebbles-sales-kpi-link p.pebbles-sales-kpi-value {
    color: var(--psales-accent);
}

.pebbles-sales-kpi-link:hover p.pebbles-sales-kpi-value {
    text-decoration: none;
    opacity: 0.8;
}

/* Filters form */
.pebbles-sales-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pebbles-sales-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.pebbles-sales-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.pebbles-sales-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--psales-text-primary);
}

.pebbles-sales-filter-item select,
.pebbles-sales-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--psales-border);
    border-radius: 4px;
    background-color: var(--psales-input-bg);
    color: var(--psales-text-primary);
}

/* Action buttons row */
.pebbles-sales-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.pebbles-sales-buttons-group {
    display: flex;
    gap: 10px;
}

.pebbles-sales-buttons-group button {
    padding: 8px 16px;
    border: none;
    background-color: var(--psales-accent);
    color: var(--psales-text-primary);
    cursor: pointer;
    border-radius: 4px;
}

.pebbles-sales-buttons-group button:hover {
    background-color: var(--psales-hover-bg);
}

.pebbles-sales-records-count {
    font-size: 1em;
    color: var(--psales-text-secondary);
}

/* Table styles */
.pebbles-sales-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--psales-card-bg);
    border: 1px solid var(--psales-border);
}

.pebbles-sales-table th,
.pebbles-sales-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--psales-border);
    color: var(--psales-text-primary);
}

.pebbles-sales-table thead th {
    font-size: 16px;
    background-color: var(--psales-card-bg);
    color: var(--psales-text-primary);
    position: relative;
    cursor: pointer;
}

.pebbles-sales-table tbody td {
    font-size: 14px;
}

.pebbles-sales-table tbody tr:nth-of-type(even) {
    background-color: var(--psales-card-bg);
}

.pebbles-sales-table tbody tr:nth-of-type(odd) {
    background-color: var(--psales-background);
}

.pebbles-sales-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--psales-border);
}

.pebbles-sales-item-image {
    max-width: 50px;
    max-height: 50px;
}

/* Pagination styles */
.pebbles-sales-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.pebbles-sales-pagination li {
    margin: 0 5px;
}

.pebbles-sales-pagination li a {
    color: var(--psales-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--psales-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--psales-card-bg);
}

.pebbles-sales-pagination li a:hover {
    background-color: var(--psales-hover-bg);
}

.pebbles-sales-pagination .active a {
    background-color: var(--psales-accent);
    color: var(--psales-text-primary);
}

.pebbles-sales-pagination .disabled a {
    color: var(--psales-text-secondary);
    border-color: var(--psales-border);
    cursor: not-allowed;
}

.pebbles-sales-pagination .break a {
    border: none;
    cursor: default;
    background-color: transparent;
}

/* Sort icon styles */
.pebbles-sales-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--psales-text-primary);
}

/* Icon Images */
.pebbles-sales-icon-image {
    width: 20px;
    height: 20px;
}

/* Add styles for Buyer and Seller links */
.pebbles-sales-table td a {
    color: var(--psales-text-primary);
    text-decoration: none;
}

.pebbles-sales-table td a:hover {
    color: var(--psales-accent);
    text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .pebbles-sales-filters-row {
        flex-direction: column;
    }
    .pebbles-sales-filter-item {
        width: 100%;
    }
    .pebbles-sales-action-buttons-row {
        flex-direction: column;
        align-items: flex-start;
    }
    .pebbles-sales-table {
        font-size: 12px;
    }
    .pebbles-sales-item-image {
        max-width: 30px;
        max-height: 30px;
    }
    .pebbles-sales-pagination li a {
        padding: 6px 8px;
        font-size: 12px;
    }
    .pebbles-sales-sort-icon {
        width: 12px;
        height: 12px;
    }
} 