/* HodlersComponent.css */

:root {
    --holders-background: #000;
    --holders-card-bg: #0a0a0a;
    --holders-text-primary: #fff;
    --holders-text-secondary: #a0a0a0;
    --holders-accent: #ff0000;
    --holders-border: #333;
    --holders-hover-bg: #111;
    --holders-input-bg: #1a1a1a;
}

.holders-main {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--holders-background);
    color: var(--holders-text-primary);
}

.holders-main .holders-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
    color: var(--holders-text-primary);
}

/* Remove old title styles */
.holders-title-white,
.holders-title-accent {
    display: none;
}

/* Filters form */
.holders-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.holders-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.holders-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.holders-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--holders-text-primary);
}

.holders-filter-item select,
.holders-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
}

.holders-filter-item input[type="text"] {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
}

.holders-filter-item input[type="text"]::placeholder {
    color: var(--holders-text-secondary);
}

/* Action buttons row */
.holders-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.holders-buttons-group {
    display: flex;
    gap: 10px;
}

.holders-buttons-group button {
    padding: 8px 16px;
    border: none;
    background-color: var(--holders-accent);
    color: var(--holders-text-primary);
    cursor: pointer;
    border-radius: 4px;
}

.holders-buttons-group button:hover {
    background-color: var(--holders-hover-bg);
}

.holders-records-count {
    font-size: 1em;
    color: var(--holders-text-secondary);
}

/* Table styles */
.holders-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--holders-card-bg);
    border: 1px solid var(--holders-border);
}

.holders-table th,
.holders-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--holders-border);
    color: var(--holders-text-primary);
}

.holders-table thead th {
    font-size: 16px;
    background-color: var(--holders-card-bg);
    color: var(--holders-text-primary);
    position: relative;
    cursor: pointer;
}

.holders-table tbody td {
    font-size: 14px;
}

.holders-table tbody tr:nth-of-type(even) {
    background-color: var(--holders-card-bg);
}

.holders-table tbody tr:nth-of-type(odd) {
    background-color: var(--holders-background);
}

/* Pagination styles */
.holders-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.holders-pagination li {
    margin: 0 5px;
}

.holders-pagination li a {
    color: var(--holders-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--holders-card-bg);
}

.holders-pagination li a:hover {
    background-color: var(--holders-hover-bg);
}

.holders-pagination .active a {
    background-color: var(--holders-accent);
    color: var(--holders-text-primary);
}

/* Icon styles */
.holders-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--holders-text-primary);
}

/* Links */
.holders-table td a {
    color: var(--holders-text-primary);
    text-decoration: none;
}

.holders-table td a:hover {
    color: var(--holders-accent);
    text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .holders-filters-row {
        flex-direction: column;
    }
    .holders-filter-item {
        width: 100%;
    }
    .holders-action-buttons-row {
        flex-direction: column;
        align-items: flex-start;
    }
}

.owner-search-input {
    background-color: var(--holders-input-bg);
    border: 1px solid var(--holders-border);
    color: var(--holders-text-primary);
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
}

.owner-search-input::placeholder {
    color: var(--holders-text-secondary);
    opacity: 0.7;
}

/* Name Link Styles with Chevron */
.name-link {
    color: var(--holders-text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.name-link:hover {
    color: var(--holders-text-primary);
}

.chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--holders-accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.name-link:hover .chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Remove old link styles */
.owner-link {
    display: none;
}

/* New Search Section Styles */
.holders-search-section {
    margin: 20px 0;
}

.holders-search-card {
    background-color: var(--holders-card-bg);
    border: 1px solid var(--holders-border);
    border-radius: 8px;
    padding: 25px;
    text-align: center;
}

.holders-search-card h2 {
    color: var(--holders-accent);
    font-size: 24px;
    margin-bottom: 15px;
}

.holders-search-container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    gap: 15px;
}

.holders-search-input-wrapper {
    flex: 1;
    position: relative;
}

.holders-search-input {
    width: 100%;
    padding: 12px 15px;
    border: 2px solid var(--holders-border);
    border-radius: 8px;
    background-color: var(--holders-background);
    color: var(--holders-text-primary);
    font-size: 15px;
    transition: all 0.3s ease;
}

.holders-search-input:focus {
    outline: none;
    border-color: var(--holders-accent);
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.1);
}

.holders-search-input::placeholder {
    color: var(--holders-text-secondary);
}

.holders-view-profile-btn {
    padding: 0 25px;
    background-color: var(--holders-accent);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease;
    white-space: nowrap;
}

.holders-view-profile-btn:hover {
    opacity: 0.9;
}

/* Suggestions Dropdown Styles */
.holders-suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--holders-card-bg);
    border: 1px solid var(--holders-border);
    border-radius: 8px;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.holders-suggestion-item {
    padding: 10px 15px;
    cursor: pointer;
    color: var(--holders-text-primary);
    transition: background-color 0.2s ease;
}

.holders-suggestion-item:hover {
    background-color: var(--holders-hover-bg);
    color: var(--holders-accent);
}

/* Mobile adjustments for search section */
@media screen and (max-width: 768px) {
    .holders-search-card {
        padding: 20px;
    }

    .holders-search-card h2 {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .holders-search-container {
        flex-direction: column;
    }

    .holders-view-profile-btn {
        width: 100%;
        padding: 12px;
    }
}

.holders-filter-input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
    font-size: 14px;
}

.holders-filter-input::placeholder {
    color: var(--holders-text-secondary);
    opacity: 0.7;
}

.holders-filter-input:focus {
    outline: none;
    border-color: var(--holders-accent);
}

/* Hide number input spinners */
.holders-filter-input::-webkit-outer-spin-button,
.holders-filter-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.holders-filter-input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.tdh-value-filter {
    display: flex;
    gap: 8px;
}

.tdh-value-filter .holders-filter-select {
    width: 120px;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
    font-size: 14px;
}

.tdh-value-filter .holders-filter-input {
    flex: 1;
}

.holders-filter-select:focus {
    outline: none;
    border-color: var(--holders-accent);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .tdh-value-filter {
        flex-direction: column;
        gap: 10px;
    }
    
    .tdh-value-filter .holders-filter-select {
        width: 100%;
    }
} 