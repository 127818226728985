.tdh-calculator-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: transparent;
    box-shadow: none;
}

.tdh-calculator-container h1 {
    text-align: center;
    color: var(--tdh-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.intro-box {
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.intro-box p {
    color: var(--text-primary);
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1.1rem;
}

.intro-box p:last-child {
    margin-bottom: 0;
}

.step-container {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
}

.step {
    flex: 1;
    text-align: center;
    padding: 1rem;
    background-color: var(--background);
    border-radius: 6px;
    border: 1px solid var(--border);
}

.step h3 {
    color: var(--accent);
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.step p {
    color: var(--text-primary);
    margin: 0;
    line-height: 1.6;
    font-size: 1rem;
}

.calculator-section {
    background-color: var(--card-bg);
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid var(--border);
}

.calculator-section h2 {
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.calculator-section h3 {
    color: var(--text-primary);
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.tdh-calculator-container form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.tdh-calculator-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tdh-calculator-container .form-group label {
    color: var(--text-primary);
    font-weight: 500;
}

.tdh-calculator-container .form-group input {
    padding: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--card-bg);
    color: var(--text-primary);
    font-size: 1rem;
}

.tdh-calculator-container .form-group input:focus {
    outline: none;
    border-color: var(--accent);
}

button {
    padding: 0.75rem 1.5rem;
    background-color: var(--accent);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.2s;
}

button:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
}

button:hover:not(:disabled) {
    background-color: #cc0000;
}

.error-message {
    color: var(--accent);
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 4px;
}

.results {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border);
}

.token-details {
    margin-bottom: 2rem;
    overflow-x: auto;
}

.token-details table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

.token-details th,
.token-details td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--border);
    color: var(--text-primary);
}

.token-details th {
    font-weight: 600;
    background-color: var(--card-bg);
}

.token-details tr:hover {
    background-color: var(--card-bg);
}

.summary {
    background-color: var(--card-bg);
    padding: 1.5rem;
    border-radius: 4px;
    border: 1px solid var(--border);
}

.summary p {
    color: var(--text-primary);
    margin-bottom: 0.75rem;
}

.summary p:last-child {
    margin-bottom: 0;
}

.important-notice {
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid var(--accent);
}

.important-notice p {
    color: var(--accent);
    font-weight: 500;
    margin: 0;
    line-height: 1.5;
}

.important-notice strong {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.input-helper-text {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: var(--text-secondary);
    font-style: italic;
}

@media (max-width: 1200px) {
    .tdh-calculator-container {
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .tdh-calculator-container {
        padding: 0.5rem;
    }

    .tdh-calculator-container h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .intro-box {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .intro-box p {
        font-size: 1rem;
    }

    .calculator-section {
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .token-details {
        margin: 0;
        width: 100%;
        overflow-x: auto;
    }

    .token-details table {
        font-size: 0.9rem;
        min-width: 600px;
    }

    .token-details th,
    .token-details td {
        padding: 0.5rem;
    }

    .important-notice {
        padding: 0.75rem;
        margin: 1rem 0;
    }

    .summary {
        padding: 1rem;
    }

    .step-container {
        flex-direction: column;
        gap: 1rem;
    }

    .step {
        padding: 0.75rem;
    }
} 