/* TdhDistributionComponent.css */

:root {
    --tdh-background: #000;
    --tdh-card-bg: #0a0a0a;
    --tdh-text-primary: #fff;
    --tdh-text-secondary: #a0a0a0;
    --tdh-accent: #ff0000;
    --tdh-border: #333;
    --tdh-hover-bg: #111;
    --tdh-input-bg: #1a1a1a;
}

.tdh-distribution-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--tdh-background) !important;
    color: var(--tdh-text-primary) !important;
}

.tdh-distribution-container .tdh-distribution-header {
    text-align: center;
    color: var(--tdh-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.tdh-distribution-container .accent-text {
    display: none;
}

.tdh-distribution-container .tdh-description-section {
    margin-bottom: 30px;
    padding: 20px;
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    line-height: 1.6;
}

.tdh-distribution-container .tdh-chart-section {
    margin-bottom: 60px;
    padding: 25px;
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.tdh-distribution-container .tdh-chart-title {
    color: var(--tdh-text-primary) !important;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--tdh-border);
}

.tdh-distribution-container .tdh-chart-wrapper {
    background-color: var(--tdh-card-bg);
    padding: 20px;
    border-radius: 8px;
    height: 400px;
}

.tdh-distribution-container .tdh-chart-notes {
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid var(--tdh-border);
    font-size: 0.9em;
    color: var(--tdh-text-secondary);
}

.tdh-distribution-container .tdh-chart-notes p {
    margin-bottom: 10px;
}

.tdh-distribution-container .tdh-chart-notes ul {
    padding-left: 20px;
    margin-bottom: 0;
}

.tdh-distribution-container .tdh-chart-notes li {
    margin-bottom: 5px;
    word-break: break-all;
}

.tdh-distribution-container .tdh-chart-notes strong {
    color: var(--tdh-text-primary);
}

.tdh-distribution-container .tdh-info-section {
    margin-bottom: 30px;
    padding: 20px;
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.tdh-distribution-container .tdh-info-section h2 {
    color: var(--tdh-text-primary);
    margin-bottom: 15px;
    font-size: 1.5em;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--tdh-border);
}

.tdh-distribution-container .tdh-info-section p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: var(--tdh-text-secondary);
}

.tdh-distribution-container .tdh-error {
    color: var(--tdh-accent);
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .tdh-distribution-container .tdh-chart-wrapper {
        height: 300px;
    }
    
    .tdh-distribution-container .tdh-chart-notes li {
        font-size: 0.8em;
    }
} 