/* TdhTableComponent.css */

/* Base styles */
:root {
    --tdh-background: #000;
    --tdh-card-bg: #0a0a0a;
    --tdh-text-primary: #fff;
    --tdh-text-secondary: #a0a0a0;
    --tdh-accent: #ff0000;
    --tdh-border: #333;
    --tdh-hover-bg: #111;
    --tdh-input-bg: #1a1a1a;
}

/* Page Container */
.tdh-table-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: var(--tdh-background);
    color: var(--tdh-text-primary);
}

/* Header */
.tdh-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.tdh-title {
    text-align: center;
    color: var(--tdh-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Remove accent class */
.tdh-title-accent {
    display: none;
}

/* Filters form */
.tdh-filters-form {
    margin-bottom: 20px;
    border: 1px solid var(--tdh-border);
    border-radius: 8px;
    background-color: var(--tdh-card-bg);
    width: 100%;
}

.tdh-filters-row {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.tdh-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.tdh-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--tdh-text-primary);
}

.tdh-filter-item select,
.tdh-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--tdh-border);
    border-radius: 4px;
    background-color: var(--tdh-input-bg);
    color: var(--tdh-text-primary);
}

/* Search container and suggestions */
.tdh-search-container {
    position: relative;
    width: 100%;
}

.tdh-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 4px;
    margin-top: 4px;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tdh-suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--tdh-text-primary);
    transition: background-color 0.2s ease;
}

.tdh-suggestions-list li:hover {
    background-color: var(--tdh-hover-bg);
}

/* Info row */
.tdh-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 10px;
}

.tdh-records-count {
    font-size: 1em;
    color: var(--tdh-text-secondary);
    white-space: nowrap;
}

/* Table section */
.table-wrapper {
    width: 100%;
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 8px;
}

.centered-table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--tdh-card-bg);
}

.centered-table th,
.centered-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--tdh-border);
    color: var(--tdh-text-primary);
}

.centered-table th {
    background-color: var(--tdh-card-bg);
    color: var(--tdh-text-primary);
    font-weight: bold;
}

.centered-table tbody tr:nth-of-type(even) {
    background-color: var(--tdh-card-bg);
}

.centered-table tbody tr:nth-of-type(odd) {
    background-color: var(--tdh-background);
}

.centered-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--tdh-border);
}

/* Table Links */
.centered-table td a {
    color: var(--tdh-text-primary);
    text-decoration: none;
}

.centered-table td a:hover {
    color: var(--tdh-accent);
    text-decoration: underline;
}

/* Tooltip Styles */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: var(--tdh-card-bg);
    color: var(--tdh-text-primary);
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid var(--tdh-border);
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
  
.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--tdh-card-bg) transparent;
}

/* Inline class for icons */
.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    color: var(--tdh-text-primary);
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    color: var(--tdh-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--tdh-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--tdh-card-bg);
}

.pagination li a:hover {
    background-color: var(--tdh-hover-bg);
}

.pagination .active a {
    background-color: var(--tdh-accent);
    color: var(--tdh-text-primary);
}

.pagination .disabled a {
    color: var(--tdh-text-secondary);
    border-color: var(--tdh-border);
    cursor: not-allowed;
}

.pagination .break a {
    border: none;
    cursor: default;
    background-color: transparent;
}

/* Info Icon */
.info-icon {
    cursor: pointer;
    color: var(--tdh-accent);
    transition: color 0.2s ease-in-out;
}
  
.info-icon:hover {
    opacity: 0.8;
}
  
/* Name Link */
.name-link {
    color: var(--tdh-text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 4px;
}
  
.name-link:hover {
    color: var(--tdh-text-primary);
}

.name-link .chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--tdh-accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.name-link:hover .chevron-icon {
    opacity: 1;
    transform: translateX(2px);
    color: var(--tdh-accent) !important;
}

/* Remove old icon styles */
.inline-icon, .info-icon {
    display: none;
}

/* Responsive design */
@media screen and (max-width: 1400px) {
    .tdh-table-container {
        padding: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .tdh-filters-row {
        flex-direction: column;
    }
    
    .tdh-filter-item {
        width: 100%;
    }

    .tdh-info-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .centered-table {
        font-size: 12px;
    }

    .tdh-filter-item label {
        font-size: 14px;
    }

    .pagination li a {
        padding: 6px 8px;
        font-size: 12px;
    }
}

/* Remove all min-width, overflow, and white-space constraints */
.tdh-table-container,
.table-wrapper,
.centered-table,
.centered-table th,
.centered-table td {
    min-width: unset;
    white-space: normal;
    overflow: visible;
}

/* SZN PPTDH Table Card */
.tdh-szn-card {
    background-color: var(--tdh-card-bg);
    border: 1px solid var(--tdh-border);
    border-radius: 8px;
    margin: 3rem 0;
    width: 100%;
}

.tdh-szn-table {
    width: 100%;
    border-collapse: collapse;
}

.tdh-szn-table th,
.tdh-szn-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--tdh-border);
    color: var(--tdh-text-primary);
}

.tdh-szn-table thead th {
    font-size: 16px;
    background-color: var(--tdh-card-bg);
    color: var(--tdh-text-primary);
    position: relative;
    white-space: nowrap;
    padding: 12px 10px;
}

.tdh-szn-table tbody td {
    font-size: 14px;
}

.tdh-szn-table tbody tr:nth-of-type(even) {
    background-color: var(--tdh-card-bg);
}

.tdh-szn-table tbody tr:nth-of-type(odd) {
    background-color: var(--tdh-background);
}

.tdh-szn-total {
    background-color: var(--tdh-card-bg) !important;
    font-weight: 600;
}

.tdh-szn-total td {
    border-top: 2px solid var(--tdh-border);
}

/* Table Icons and Links */
.tdh-icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
}

.tdh-icon-link:hover {
    opacity: 0.8;
}

.tdh-table-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}
  
