/* MyPortfolioComponent.css */

:root {
    --mp-background: #000;
    --mp-card-bg: #0a0a0a;
    --mp-text-primary: #fff;
    --mp-text-secondary: #a0a0a0;
    --mp-accent: #ff0000;
    --mp-border: #333;
    --mp-hover-bg: #111;
    --mp-input-bg: #1a1a1a;
}

/* Container for the entire component */
.my-portfolio-container {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--mp-background);
    color: var(--mp-text-primary);
}

/* Header */
.my-portfolio-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.my-portfolio-title {
    text-align: center;
    color: var(--mp-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Remove accent-text class */
.my-portfolio-title-accent {
    display: none;
}

/* Subheader */
.my-portfolio-sub-header {
    font-size: 1.5em;
    color: var(--mp-text-secondary);
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

/* Form container */
.my-portfolio-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Form */
.my-portfolio-form {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

/* Input and button */
.my-portfolio-form input[type="text"],
.my-portfolio-form button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--mp-border);
    border-radius: 4px;
    background-color: var(--mp-input-bg);
    color: var(--mp-text-primary);
}

.my-portfolio-form input[type="text"] {
    margin-right: 10px;
    flex: 1;
    max-width: 300px;
}

.my-portfolio-form button {
    background-color: var(--mp-accent);
    color: var(--mp-text-primary);
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
}

.my-portfolio-form button:hover {
    background-color: var(--mp-hover-bg);
}

/* Description */
.my-portfolio-description {
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    color: var(--mp-text-secondary);
}

/* Error message */
.my-portfolio-error-message {
    color: var(--mp-accent);
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
}

/* Loading message */
.my-portfolio-loading-message {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--mp-text-secondary);
}

/* Portfolio valuation table */
.my-portfolio-complete-portfolio-data {
    margin-bottom: 30px;
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: var(--mp-card-bg);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table th,
.my-portfolio-complete-portfolio-data .my-portfolio-styled-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--mp-border);
    color: var(--mp-text-primary);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table th {
    background-color: var(--mp-card-bg);
    color: var(--mp-text-primary);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table tbody tr:nth-of-type(even) {
    background-color: var(--mp-card-bg);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table tbody tr:nth-of-type(odd) {
    background-color: var(--mp-background);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--mp-border);
}

.my-portfolio-complete-portfolio-data .my-portfolio-styled-table tbody tr.my-portfolio-total-row {
    background-color: var(--mp-card-bg);
    font-weight: bold;
}

/* Filter container - updated to match PricesComponent */
.my-portfolio-filter-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--mp-card-bg);
    border: 1px solid var(--mp-border);
    border-radius: 8px;
}

.my-portfolio-filter-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.my-portfolio-filter-section label {
    color: var(--mp-text-primary);
    font-weight: normal;
}

.my-portfolio-search-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.my-portfolio-search-section label {
    color: var(--mp-text-primary);
    font-weight: normal;
}

.my-portfolio-filter-section select {
    width: 100%;
    background-color: var(--mp-input-bg);
    color: var(--mp-text-primary);
    border: 1px solid var(--mp-border);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    height: 40px;
}

.my-portfolio-search-section input {
    width: 100%;
    background-color: var(--mp-input-bg);
    color: var(--mp-text-primary);
    border: 1px solid var(--mp-border);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    height: 40px;
}

/* Suggestions List */
.my-portfolio-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--mp-card-bg);
    border: 1px solid var(--mp-border);
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-portfolio-suggestions-list li {
    padding: 10px 12px;
    cursor: pointer;
    color: var(--mp-text-primary);
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--mp-border);
}

.my-portfolio-suggestions-list li:last-child {
    border-bottom: none;
}

.my-portfolio-suggestions-list li:hover {
    background-color: var(--mp-hover-bg);
}

/* Portfolio details table */
.my-portfolio-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: var(--mp-card-bg);
}

.my-portfolio-details-table th,
.my-portfolio-details-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--mp-border);
    color: var(--mp-text-primary);
}

.my-portfolio-details-table th {
    background-color: var(--mp-card-bg);
    color: var(--mp-text-primary);
    cursor: pointer;
    position: relative;
}

.my-portfolio-details-table tbody tr:nth-of-type(even) {
    background-color: var(--mp-card-bg);
}

.my-portfolio-details-table tbody tr:nth-of-type(odd) {
    background-color: var(--mp-background);
}

.my-portfolio-details-table tbody tr.my-portfolio-highlight {
    background-color: var(--mp-hover-bg);
}

.my-portfolio-details-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--mp-border);
}

/* Sort Icons */
.my-portfolio-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--mp-text-primary);
}

/* Remove duplicate sort indicators added via CSS ::after */
.my-portfolio-details-table th::after {
    display: none;
}

.my-portfolio-details-table th.my-portfolio-sorted-asc::after {
    display: none;
}

.my-portfolio-details-table th.my-portfolio-sorted-desc::after {
    display: none;
}

/* Name Link */
.my-portfolio-name-link {
    color: var(--mp-text-primary);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
}

.my-portfolio-name-link:hover {
    color: var(--mp-accent);
}

.my-portfolio-name-link:hover .my-portfolio-inline-icon {
    transform: translateX(2px);
    transition: transform 0.2s ease-in-out;
}

.my-portfolio-inline-icon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    color: var(--mp-accent);
    vertical-align: middle;
}

/* Link Container */
.my-portfolio-link-container {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.my-portfolio-green-button {
    background-color: var(--mp-accent);
    color: var(--mp-text-primary);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
    border-radius: 4px;
}

.my-portfolio-green-button:hover {
    background-color: var(--mp-hover-bg);
}

/* Records count */
.my-portfolio-records-count {
    text-align: right;
    margin: 10px 0;
    color: var(--mp-text-secondary);
}

/* Pagination */
.my-portfolio-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.my-portfolio-pagination li {
    margin: 0 5px;
}

.my-portfolio-pagination li a {
    color: var(--mp-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--mp-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--mp-card-bg);
}

.my-portfolio-pagination li a:hover {
    background-color: var(--mp-hover-bg);
}

.my-portfolio-pagination .active a {
    background-color: var(--mp-accent);
    color: var(--mp-text-primary);
}

.my-portfolio-pagination .disabled a {
    color: var(--mp-text-secondary);
    border-color: var(--mp-border);
    cursor: not-allowed;
}

.my-portfolio-pagination .break a {
    border: none;
    cursor: default;
    background-color: transparent;
}

/* Header content */
.my-portfolio-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .my-portfolio-filter-container {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .my-portfolio-form {
        flex-direction: column;
        align-items: center;
    }

    .my-portfolio-form input[type="text"] {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
        max-width: none;
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .my-portfolio-details-table {
        font-size: 12px;
    }

    .my-portfolio-pagination li a {
        padding: 6px 8px;
        font-size: 12px;
    }

    .my-portfolio-inline-icon {
        width: 12px;
        height: 12px;
    }
}

/* Marketplace icons and links */
.my-portfolio-marketplace-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    transition: transform 0.2s ease-in-out;
}

.my-portfolio-marketplace-icon:hover {
    transform: scale(1.1);
}

.my-portfolio-bid-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.my-portfolio-marketplace-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

/* Adjust table cell padding for marketplace icons */
.my-portfolio-details-table td:nth-last-child(-n+3) {
    padding: 4px;
    text-align: center;
}

/* Center align the marketplace icon cells */
.my-portfolio-details-table th:nth-last-child(-n+3) {
    text-align: center;
}

/* Make the "Fuck Off" text stand out */
.my-portfolio-details-table td:nth-child(5) {
    font-weight: normal;
    color: var(--mp-text-primary);
}

/* Add specific styling for the special text */
.my-portfolio-special-text {
    font-weight: bold;
    color: var(--mp-accent) !important;
}

.my-portfolio-complete-portfolio-data select {
    background-color: var(--mp-input-bg);
    color: var(--mp-text-primary);
    border: 1px solid var(--mp-border);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    height: 40px;
    width: auto;
}

.my-portfolio-complete-portfolio-data select option {
    background-color: var(--mp-input-bg);
    color: var(--mp-text-primary);
}

.my-portfolio-sorted-asc {
    transform: rotate(180deg);
}

.my-portfolio-sorted-desc {
    transform: rotate(0deg);
}

/* Name Link Styles */
.my-portfolio-name-link {
    color: var(--mp-text-primary) !important;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 4px;
}

.my-portfolio-name-link:hover {
    color: var(--mp-text-primary) !important;
}

.my-portfolio-inline-icon {
    width: 14px;
    height: 14px;
    color: var(--mp-accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.my-portfolio-name-link:hover .my-portfolio-inline-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Table styles */
.my-portfolio-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--mp-card-bg);
    border: 1px solid var(--mp-border);
}

.my-portfolio-table th,
.my-portfolio-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--mp-border);
    color: var(--mp-text-primary);
}

.my-portfolio-table thead th {
    font-size: 16px;
    background-color: var(--mp-card-bg);
    color: var(--mp-text-primary);
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    padding: 12px 10px;
}

.my-portfolio-table tbody td {
    font-size: 14px;
}

.my-portfolio-table tbody tr:nth-of-type(even) {
    background-color: var(--mp-card-bg);
}

.my-portfolio-table tbody tr:nth-of-type(odd) {
    background-color: var(--mp-background);
}

/* Links in table */
.my-portfolio-table td a {
    color: var(--mp-text-primary) !important;
    text-decoration: none;
}

.my-portfolio-table td a:hover {
    color: var(--mp-accent) !important;
}

/* Table styles */
.my-portfolio-details-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--mp-border);
}

/* Remove the specific Supply column font styling */
.my-portfolio-details-table td:nth-child(4) { /* Supply column */
    text-align: center;
    font-family: inherit;
    padding: 8px 10px;
}

/* Apply consistent numeric column styling */
.my-portfolio-details-table td:nth-child(4), /* Supply */
.my-portfolio-details-table td:nth-child(6), /* Highest Bid */
.my-portfolio-details-table td:nth-child(7), /* Floor Price */
.my-portfolio-details-table td:nth-child(8), /* Last Sale */
.my-portfolio-details-table td:nth-child(9), /* PPTDH */
.my-portfolio-details-table td:nth-child(11), /* Bid Valuation */
.my-portfolio-details-table td:nth-child(12), /* Valuation With Depth */
.my-portfolio-details-table td:nth-child(13) { /* Ask Valuation */
    text-align: center;
    font-family: inherit;
    padding: 8px 10px;
}

.my-portfolio-name-link {
    color: var(--mp-text-primary);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
}

.my-portfolio-name-link:hover {
    color: var(--mp-accent);
}

.my-portfolio-inline-icon {
    width: 16px;
    height: 16px;
}
