/* PebblesMainComponent.css */

:root {
    --pebbles-background: #000;
    --pebbles-card-bg: #0a0a0a;
    --pebbles-text-primary: #fff;
    --pebbles-text-secondary: #a0a0a0;
    --pebbles-accent: #ff0000;
    --pebbles-border: #333;
    --pebbles-hover-bg: #111;
    --pebbles-input-bg: #1a1a1a;
}

/* Hide Owner column */
.hidden-column {
    display: none;
}

.pebbles-main {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--pebbles-background);
    color: var(--pebbles-text-primary);
}

.pebbles-main .pebbles-title {
    text-align: center;
    color: var(--pebbles-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.pebbles-main .pebbles-title a {
    color: var(--pebbles-accent);
    text-decoration: none;
}

.pebbles-main .pebbles-title a:hover {
    text-decoration: underline;
}

/* Add spacing between data and icons */
.pebbles-icon-image {
    margin-left: 8px;
    vertical-align: middle;
}

/* Filters form */
.pebbles-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pebbles-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.pebbles-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.pebbles-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--pebbles-text-primary);
}

.pebbles-filter-item select,
.pebbles-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--pebbles-border);
    border-radius: 4px;
    background-color: var(--pebbles-input-bg);
    color: var(--pebbles-text-primary);
}

.pebbles-filter-item input[type="text"] {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--pebbles-border);
    border-radius: 4px;
    background-color: var(--pebbles-input-bg);
    color: var(--pebbles-text-primary);
}

.pebbles-filter-item input[type="text"]::placeholder {
    color: var(--pebbles-text-secondary);
}

/* Action buttons row */
.pebbles-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.pebbles-buttons-group {
    display: flex;
    gap: 10px;
}

.pebbles-buttons-group button {
    padding: 8px 16px;
    border: none;
    background-color: var(--pebbles-accent);
    color: var(--pebbles-text-primary);
    cursor: pointer;
    border-radius: 4px;
}

.pebbles-buttons-group button:hover {
    background-color: var(--pebbles-hover-bg);
}

.pebbles-records-count {
    font-size: 1em;
    color: var(--pebbles-text-secondary);
}

/* Table styles */
.pebbles-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--pebbles-card-bg);
    border: 1px solid var(--pebbles-border);
}

.pebbles-table th,
.pebbles-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--pebbles-border);
    color: var(--pebbles-text-primary);
}

.pebbles-table thead th {
    font-size: 16px;
    background-color: var(--pebbles-card-bg);
    color: var(--pebbles-text-primary);
    position: relative;
    cursor: pointer;
}

.pebbles-table tbody td {
    font-size: 14px;
}

.pebbles-table tbody tr:nth-of-type(even) {
    background-color: var(--pebbles-card-bg);
}

.pebbles-table tbody tr:nth-of-type(odd) {
    background-color: var(--pebbles-background);
}

.pebbles-item-image {
    max-width: 50px;
    max-height: 50px;
    cursor: pointer;
}

/* Pagination styles */
.pebbles-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.pebbles-pagination li {
    margin: 0 5px;
}

.pebbles-pagination li a {
    color: var(--pebbles-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--pebbles-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--pebbles-card-bg);
}

.pebbles-pagination li a:hover {
    background-color: var(--pebbles-hover-bg);
}

.pebbles-pagination .active a {
    background-color: var(--pebbles-accent);
    color: var(--pebbles-text-primary);
}

/* Icon styles */
.pebbles-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--pebbles-text-primary);
}

.pebbles-icon-image {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .pebbles-filters-row {
        flex-direction: column;
    }
    .pebbles-filter-item {
        width: 100%;
    }
    .pebbles-action-buttons-row {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Links */
.pebbles-table td a {
    color: var(--pebbles-text-primary);
    text-decoration: none;
}

.pebbles-table td a:hover {
    color: var(--pebbles-accent);
    text-decoration: underline;
}

/* KPI Dashboard */
.pebbles-dashboard {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 30px 0;
    padding: 0 15px;
}

.pebbles-kpi-box {
    flex: 1;
    background-color: var(--pebbles-card-bg);
    border: 1px solid var(--pebbles-border);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pebbles-kpi-box h3 {
    color: var(--pebbles-text-secondary);
    font-size: 1em;
    margin: 0 0 10px 0;
    font-weight: normal;
}

.pebbles-kpi-box p {
    color: var(--pebbles-text-primary);
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
}

.pebbles-kpi-box p.pebbles-kpi-value {
    color: var(--pebbles-accent);
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
}

/* Responsive adjustments for KPI boxes */
@media screen and (max-width: 768px) {
    .pebbles-dashboard {
        flex-direction: column;
    }
    
    .pebbles-kpi-box {
        width: 100%;
    }
}

/* Owner address style */
.pebbles-table td.owner-address {
    font-family: monospace;
    font-size: 0.9em;
}

/* TDH column style */
.pebbles-table td[data-column="tdh"] {
    font-weight: 500;
    color: var(--pebbles-accent);
}

.owner-search-input {
    background-color: var(--pebbles-input-bg);
    border: 1px solid var(--pebbles-border);
    color: var(--pebbles-text-primary);
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
}

.owner-search-input::placeholder {
    color: var(--pebbles-text-secondary);
    opacity: 0.7;
}

.owner-link {
    color: var(--pebbles-text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
}

.owner-link:hover {
    color: var(--pebbles-accent);
    text-decoration: underline;
}

/* Hodlers column styling */
.hodlers-column {
    font-weight: 500;
    color: var(--pebbles-text-primary);
}

.hodlers-column:hover {
    color: var(--pebbles-accent);
}

.hodlers-link {
    color: var(--pebbles-text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 4px;
}

.hodlers-link:hover {
    color: var(--pebbles-accent);
    text-decoration: underline;
}

.pebbles-chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--pebbles-accent);
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.hodlers-link:hover .pebbles-chevron-icon {
    opacity: 1;
    transform: translateX(2px);
} 