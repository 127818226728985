.holders-individual-main {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--holders-background);
    color: var(--holders-text-primary);
}

.holders-individual-title {
    text-align: center;
    margin-bottom: 60px;
    font-size: 32px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
}

.holders-individual-section {
    margin: 30px 0;
}

.holders-section-spacing {
    margin-bottom: 60px;
}

.holders-individual-section-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: var(--holders-accent);
    text-align: center;
}

.holders-individual-error {
    color: var(--holders-accent);
    text-align: center;
    padding: 20px;
    font-size: 18px;
}

.holders-individual-loading {
    color: var(--holders-text-primary);
    text-align: center;
    padding: 20px;
    font-size: 18px;
}

/* Table styles */
.holders-individual-main .holders-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--holders-card-bg);
    border: 1px solid var(--holders-border);
}

.holders-individual-main .holders-table th {
    background-color: var(--holders-card-bg);
}

.holders-individual-main .holders-table th,
.holders-individual-main .holders-table td {
    text-align: center;
    padding: 12px 15px;
    border: 1px solid var(--holders-border);
    color: var(--holders-text-primary);
}

.holders-individual-main .holders-table thead th {
    font-size: 16px;
    background-color: var(--holders-card-bg);
    color: var(--holders-text-primary);
}

.holders-individual-main .holders-table tbody td {
    font-size: 16px;
}

/* Link styles */
.artist-link, .token-link {
    color: var(--holders-text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
}

.artist-link:hover, .token-link:hover {
    color: var(--holders-accent);
    text-decoration: underline;
}

/* Marketplace icons */
.marketplace-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    transition: opacity 0.2s ease;
}

.marketplace-link:hover .marketplace-icon {
    opacity: 0.8;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .holders-individual-main {
        padding: 0 10px;
    }

    .holders-individual-title {
        font-size: 24px;
    }

    .holders-individual-section-title {
        font-size: 20px;
    }

    .holders-individual-main .holders-table th,
    .holders-individual-main .holders-table td {
        padding: 8px;
        font-size: 14px;
    }

    .marketplace-icon {
        width: 16px;
        height: 16px;
    }
}

/* Floor price display */
.floor-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.floor-price span {
    margin-right: 5px;
}

.floor-price .marketplace-icon {
    width: 16px;
    height: 16px;
}

/* Filters form */
.holders-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.holders-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.holders-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.holders-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--holders-text-primary);
}

.holders-filter-item select {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
}

.holders-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.holders-records-count {
    font-size: 1em;
    color: var(--holders-text-secondary);
}

/* Name Link Styles with Chevron */
.name-link {
    color: var(--holders-text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.name-link:hover {
    color: var(--holders-text-primary);
}

.chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--holders-accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.name-link:hover .chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Portfolio Value Link */
.portfolio-value-link {
    color: var(--holders-text-primary);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease-in-out;
}

.portfolio-value-link:hover {
    color: var(--holders-text-primary);
}

.portfolio-value-link .chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--holders-accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.portfolio-value-link:hover .chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Header content with sort icons */
.holders-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.holders-sort-icon {
    width: 16px;
    height: 16px;
    color: var(--holders-text-primary);
    transition: transform 0.2s ease;
}

.holders-sorted-asc .holders-sort-icon {
    transform: rotate(180deg);
}

/* Search container and suggestions styles */
.holders-search-container {
    position: relative;
    width: 100%;
}

.holders-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: var(--holders-input-bg);
    border: 1px solid var(--holders-border);
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
}

.holders-suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--holders-text-primary);
    transition: background-color 0.2s ease;
}

.holders-suggestions-list li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Update name search input styles */
.name-search-input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--holders-border);
    border-radius: 4px;
    background-color: var(--holders-input-bg);
    color: var(--holders-text-primary);
}

.name-search-input:focus {
    outline: none;
    border-color: var(--holders-accent);
    border-radius: 4px 4px 0 0;
}

/* Update table header styles for sorting */
.holders-table th {
    position: relative;
    padding: 12px 8px;
    background-color: var(--holders-card-bg);
    color: var(--holders-text-primary);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.holders-table th:hover {
    background-color: var(--holders-hover-bg);
}

/* Filters form layout */
.holders-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.holders-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.holders-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.holders-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--holders-text-primary);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .holders-filters-row {
        flex-direction: column;
    }
    
    .holders-filter-item {
        width: 100%;
    }
}

/* Portfolio Value table styles */
.portfolio-total-row {
    background-color: var(--holders-card-bg) !important;
    border-top: 2px solid var(--holders-border);
}

.portfolio-total-row td {
    color: var(--holders-accent);
    font-size: 1.1em;
    padding: 12px 8px;
}

.portfolio-grand-total-row {
    background-color: var(--holders-card-bg) !important;
    border-top: 2px solid var(--holders-border);
}

.portfolio-grand-total-row td {
    color: var(--holders-accent);
    font-size: 1.2em;
    padding: 12px 8px;
    font-weight: bold;
}

/* Update spacing between sections */
.holders-individual-section + .holders-individual-section {
    margin-top: 60px;
}

/* Ensure consistent table styling */
.holders-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--holders-card-bg);
    border: 1px solid var(--holders-border);
}

.holders-table th,
.holders-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--holders-border);
    color: var(--holders-text-primary);
}

.holders-table tbody tr:nth-of-type(even) {
    background-color: var(--holders-card-bg);
}

.holders-table tbody tr:nth-of-type(odd) {
    background-color: var(--holders-background);
}

/* Portfolio explainer text */
.portfolio-explainer {
    margin: 15px 0;
    color: var(--holders-text-primary);
    text-align: center;
    line-height: 1.5;
}

.portfolio-explainer a {
    color: var(--holders-accent);
    text-decoration: none;
}

.portfolio-explainer a:hover {
    text-decoration: underline;
}

.holders-individual-main-header {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
    text-align: center;
    width: 100%;
} 