/* SalesBotAllTimeComponent.css */

:root {
    --sbot-background: #000;
    --sbot-card-bg: #0a0a0a;
    --sbot-text-primary: #fff;
    --sbot-text-secondary: #a0a0a0;
    --sbot-accent: #ff0000;
    --sbot-border: #333;
    --sbot-hover-bg: #111;
    --sbot-input-bg: #1a1a1a;
}

/* Styles for the Sales Bot All Time Component */
.sales-bot-all-time {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--sbot-background);
    color: var(--sbot-text-primary);
}

.sales-bot-all-time .sales-bot-title {
    text-align: center;
    color: var(--sbot-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.accent-text {
    display: none;
}

/* Filters form */
.sales-bot-filters-form {
    margin-bottom: 20px;
}

.sales-bot-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.sales-bot-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.sales-bot-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--sbot-text-primary);
}

.sales-bot-filter-item select,
.sales-bot-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--sbot-border);
    border-radius: 4px;
    background-color: var(--sbot-input-bg);
    color: var(--sbot-text-primary);
}

.sales-bot-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--sbot-card-bg);
    border: 1px solid var(--sbot-border);
    border-top: none;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.sales-bot-suggestions-list li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid var(--sbot-border);
    color: var(--sbot-text-primary);
}

.sales-bot-suggestions-list li:hover {
    background-color: var(--sbot-hover-bg);
}

/* Action buttons row */
.sales-bot-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.sales-bot-buttons-group {
    display: flex;
    gap: 10px;
}

.sales-bot-buttons-group button {
    padding: 8px 16px;
    border: none;
    background-color: var(--sbot-accent);
    color: var(--sbot-text-primary);
    cursor: pointer;
    border-radius: 4px;
}

.sales-bot-buttons-group button:hover {
    background-color: var(--sbot-hover-bg);
}

.sales-bot-records-and-download {
    display: flex;
    align-items: center;
    gap: 20px;
}

.sales-bot-records-and-download span {
    font-size: 1em;
    color: var(--sbot-text-secondary);
}

.sales-bot-records-and-download .btn {
    background-color: var(--sbot-accent);
    color: var(--sbot-text-primary);
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.sales-bot-records-and-download .btn:hover {
    background-color: var(--sbot-hover-bg);
}

/* Table styles specific to Sales Bot component */
.sales-bot-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--sbot-card-bg);
    border: 1px solid var(--sbot-border);
}

.sales-bot-table th,
.sales-bot-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--sbot-border);
    color: var(--sbot-text-primary);
}

.sales-bot-table thead th {
    font-size: 16px;
    background-color: var(--sbot-card-bg);
    color: var(--sbot-text-primary);
    position: relative;
    cursor: pointer;
}

.sales-bot-table tbody td {
    font-size: 14px;
}

.sales-bot-table tbody tr:nth-of-type(even) {
    background-color: var(--sbot-card-bg);
}

.sales-bot-table tbody tr:nth-of-type(odd) {
    background-color: var(--sbot-background);
}

.sales-bot-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--sbot-border);
}

.sales-bot-item-image {
    max-width: 50px;
    max-height: 50px;
}

/* Pagination styles */
.sales-bot-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.sales-bot-pagination li {
    margin: 0 5px;
}

.sales-bot-pagination li a {
    color: var(--sbot-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--sbot-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--sbot-card-bg);
}

.sales-bot-pagination li a:hover {
    background-color: var(--sbot-hover-bg);
}

.sales-bot-pagination .active a {
    background-color: var(--sbot-accent);
    color: var(--sbot-text-primary);
}

.sales-bot-pagination .disabled a {
    color: var(--sbot-text-secondary);
    border-color: var(--sbot-border);
    cursor: not-allowed;
}

.sales-bot-pagination .break a {
    border: none;
    cursor: default;
    background-color: transparent;
}

/* Adjust icon styles */
.sales-bot-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--sbot-text-primary);
}

/* Remove duplicate sort indicators added via CSS ::after */
.sales-bot-table th::after {
    display: none;
}

.sales-bot-table th.sales-bot-sorted-asc::after {
    display: none;
}

.sales-bot-table th.sales-bot-sorted-desc::after {
    display: none;
}

/* Optional: Style for clickable table headers */
.sales-bot-table th {
    cursor: pointer;
}

/* Info Icon */
.sales-bot-info-icon {
    cursor: pointer;
    color: var(--sbot-text-primary);
    transition: color 0.2s ease-in-out;
}

.sales-bot-info-icon:hover {
    color: var(--sbot-accent);
}

/* Name Link */
.sales-bot-name-link {
    color: var(--sbot-text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 4px;
}

.sales-bot-name-link:hover {
    color: var(--sbot-text-primary);
}

.sales-bot-chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--sbot-accent);
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.sales-bot-name-link:hover .sales-bot-chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Remove old icon styles */
.sales-bot-inline-icon {
    display: none;
}

/* Icon Images */
.sales-bot-icon-image {
    width: 20px;
    height: 20px;
}

/* Mobile filter toggle */
.filter-toggle-button {
    display: none;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background-color: var(--sbot-card-bg);
    border: 1px solid var(--sbot-border);
    color: var(--sbot-text-primary);
    font-size: 1.1em;
    cursor: pointer;
    text-align: left;
    border-radius: 4px;
}

.filter-toggle-button .chevron-icon {
    float: right;
    transition: transform 0.3s ease;
}

.filter-toggle-button .chevron-icon.open {
    transform: rotate(180deg);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .filter-toggle-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sales-bot-filters-form {
        display: none;
        margin-bottom: 20px;
    }

    .sales-bot-filters-form.show {
        display: block;
    }

    .sales-bot-filters-row {
        flex-direction: column;
    }

    .sales-bot-filter-item {
        width: 100%;
    }

    .sales-bot-action-buttons-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .sales-bot-records-and-download {
        flex-direction: column;
        align-items: flex-start;
    }

    .sales-bot-buttons-group {
        margin-bottom: 10px;
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .sales-bot-table {
        font-size: 12px;
    }

    .sales-bot-item-image {
        max-width: 30px;
        max-height: 30px;
    }

    .sales-bot-pagination li a {
        padding: 6px 8px;
        font-size: 12px;
    }

    .sales-bot-sort-icon {
        width: 12px;
        height: 12px;
    }
}

/* Add styles for Buyer and Seller links */
.sales-bot-table td a {
    color: var(--sbot-text-primary);
    text-decoration: none;
}

.sales-bot-table td a:hover {
    color: var(--sbot-accent);
    text-decoration: underline;
}

.supply-value-filter {
    display: flex;
    gap: 8px;
}

.supply-value-filter .sales-bot-filter-select {
    width: 120px;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--sbot-border);
    border-radius: 4px;
    background-color: var(--sbot-input-bg);
    color: var(--sbot-text-primary);
    font-size: 14px;
}

.supply-value-filter .sales-bot-filter-input {
    flex: 1;
}

.sales-bot-filter-select:focus {
    outline: none;
    border-color: var(--sbot-accent);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .supply-value-filter {
        flex-direction: column;
        gap: 10px;
    }
    
    .supply-value-filter .sales-bot-filter-select {
        width: 100%;
    }
}
