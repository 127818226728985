/* ArtistComponent.css */

:root {
    --artist-background: #000;
    --artist-card-bg: #0a0a0a;
    --artist-text-primary: #fff;
    --artist-text-secondary: #a0a0a0;
    --artist-accent: #ff0000;
    --artist-border: #333;
    --artist-hover-bg: #111;
    --artist-input-bg: #1a1a1a;
}

.artist-main {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--artist-background);
    color: var(--artist-text-primary);
}

.artist-main .artist-title {
    text-align: center;
    color: var(--artist-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Filters form */
.artist-filters-form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.artist-filters-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    margin-bottom: 15px;
}

.artist-filter-item {
    flex: 0 0 300px; /* Fixed width for search */
}

.artist-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--artist-text-primary);
}

.artist-filter-item input[type="text"] {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--artist-border);
    border-radius: 4px;
    background-color: var(--artist-input-bg);
    color: var(--artist-text-primary);
}

.artist-filter-item input[type="text"]::placeholder {
    color: var(--artist-text-secondary);
}

/* Action buttons row */
.artist-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.artist-buttons-group {
    display: flex;
    gap: 10px;
}

.artist-buttons-group button {
    padding: 8px 16px;
    border: none;
    background-color: var(--artist-accent);
    color: var(--artist-text-primary);
    cursor: pointer;
    border-radius: 4px;
}

.artist-buttons-group button:hover {
    background-color: var(--artist-hover-bg);
}

.artist-records-count {
    font-size: 1em;
    color: var(--artist-text-secondary);
}

/* Table styles */
.artist-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--artist-card-bg);
    border: 1px solid var(--artist-border);
}

.artist-table th,
.artist-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--artist-border);
    color: var(--artist-text-primary);
}

.artist-table thead th {
    font-size: 16px;
    background-color: var(--artist-card-bg);
    color: var(--artist-text-primary);
    position: relative;
    cursor: pointer;
}

.artist-table tbody td {
    font-size: 14px;
}

.artist-table tbody tr:nth-of-type(even) {
    background-color: var(--artist-card-bg);
}

.artist-table tbody tr:nth-of-type(odd) {
    background-color: var(--artist-background);
}

.artist-item-image {
    max-width: 50px;
    max-height: 50px;
    width: 50px;  /* Fixed width */
    height: 50px; /* Fixed height */
    cursor: zoom-in;
    opacity: 1; /* Start visible */
    background-color: var(--artist-card-bg);
    border-radius: 4px;
    object-fit: cover; /* Ensure image covers area properly */
    display: block; /* Prevent inline spacing issues */
    transition: transform 0.2s ease;
    will-change: transform; /* Optimize for animations */
}

.artist-item-image:hover {
    transform: scale(1.1);
}

/* Image container styles */
.artist-table td:nth-child(4) {
    width: 60px;
    height: 60px;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    background-color: var(--artist-card-bg);
}

.artist-table td:nth-child(4) a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Loading state styles */
.artist-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    color: var(--artist-text-primary);
}

.artist-loading p {
    margin-top: 20px;
    font-size: 16px;
}

/* Pagination styles */
.artist-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.artist-pagination li {
    margin: 0 5px;
}

.artist-pagination li a {
    color: var(--artist-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--artist-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--artist-card-bg);
}

.artist-pagination li a:hover {
    background-color: var(--artist-hover-bg);
}

.artist-pagination .active a {
    background-color: var(--artist-accent);
    color: var(--artist-text-primary);
}

/* Icon styles */
.artist-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--artist-text-primary);
}

/* Links */
.artist-table td a {
    color: var(--artist-text-primary);
    text-decoration: none;
}

.artist-table td a:hover {
    color: var(--artist-accent);
    text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .artist-filters-row {
        flex-direction: column;
    }
    .artist-filter-item {
        width: 100%;
    }
    .artist-action-buttons-row {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Icon styles */
.artist-icon-image {
    width: 20px;
    height: 20px;
    cursor: pointer;
    vertical-align: middle;
}

/* Art download link styles */
.artist-download-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: var(--artist-accent);
    color: var(--artist-text-primary);
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.artist-download-link:hover {
    background-color: #cc0000;
    text-decoration: none;
    color: var(--artist-text-primary);
}

.artist-download-link svg {
    width: 16px;
    height: 16px;
}

/* Title accent */
.artist-accent {
    display: none;
}

.artist-download-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 250px;
}

.artist-download-progress {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    background-color: var(--artist-card-bg);
    border-radius: 4px;
    border: 1px solid var(--artist-border);
}

/* Update download button styles */
.artist-download-button {
    height: 35px;
    padding: 0 15px;
    margin: 0;
    background-color: var(--artist-accent);
    color: var(--artist-text-primary);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    justify-content: center;
    transition: all 0.2s ease;
    min-width: 180px;
}

.artist-download-button:hover:not(:disabled) {
    background-color: #cc0000;
    opacity: 1;
}

.artist-download-button:disabled {
    cursor: not-allowed;
}

.artist-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.artist-download-section {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

/* Progress bar container */
.artist-progress-container {
    width: 100%;
    background-color: var(--artist-border);
    height: 4px;
    border-radius: 2px;
    margin-top: 8px;
    overflow: hidden;
    display: none; /* Hidden by default */
}

.artist-progress-container.active {
    display: block;
}

.artist-progress-bar {
    height: 100%;
    background-color: var(--artist-accent);
    transition: width 0.3s ease;
}

/* Mobile adjustments */
@media screen and (max-width: 1024px) {
    .artist-filters-row {
        flex-direction: column;
        align-items: stretch;
    }

    .artist-filter-item {
        flex: 1;
        width: 100%;
    }

    .artist-download-section {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .artist-season-select {
        flex: 1;
        width: auto;
        max-width: none;
    }
}

.artist-season-select {
    padding: 8px 10px;
    border-radius: 4px;
    background-color: var(--artist-input-bg);
    color: var(--artist-text-primary);
    border: 1px solid var(--artist-border);
    font-size: 14px;
    width: 200px;
    cursor: pointer;
    height: 35px; /* Match input height */
}

.artist-season-select:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.artist-season-select option {
    background-color: var(--artist-card-bg);
    color: var(--artist-text-primary);
} 