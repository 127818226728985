/* Footer Styles */
.footer-container {
  flex-shrink: 0;
  background-color: var(--card-bg);
  color: var(--text-primary);
  padding: 20px 0;
  margin-top: 20px;
  border-top: 1px solid var(--border);
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 15px;
}

.creator-info {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.donation-message {
  font-size: 1em;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

/* Additional Insights paragraph */
.additional-insights {
  font-size: 1em;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

/* Make the link white, bold, and underlined */
.additional-insights a {
  color: #ffffff !important;
  font-weight: bold;
  text-decoration: underline;
}

.social-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.social-links a {
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.social-links a:hover {
  color: var(--accent);
  text-decoration: none;
}

.email-link {
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
}

.email-link:hover {
  color: var(--accent);
}

.footer-contact-button {
  margin-top: 10px;
}

.footer-contact-button .green-button {
  background-color: var(--card-bg);
  color: var(--text-primary);
  border: 1px solid var(--border);
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.footer-contact-button .green-button:hover {
  background-color: var(--hover-bg);
  color: var(--accent);
  border-color: var(--accent);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .social-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
