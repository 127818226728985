/* MyPortfolioBidsComponent.css */

:root {
    --mpb-background: #000;
    --mpb-card-bg: #0a0a0a;
    --mpb-text-primary: #fff;
    --mpb-text-secondary: #a0a0a0;
    --mpb-accent: #ff0000;
    --mpb-border: #333;
    --mpb-hover-bg: #111;
    --mpb-input-bg: #1a1a1a;
}

/* Container for the entire component */
.my-portfolio-bids-container {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: var(--mpb-background) !important;
    color: var(--mpb-text-primary) !important;
}

/* Header */
.my-portfolio-bids-page-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.my-portfolio-bids-title {
    text-align: center;
    color: var(--mpb-text-primary) !important;
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: bold;
}

.my-portfolio-bids-title-accent {
    color: var(--mpb-accent) !important;
}

/* Loading and Error Messages */
.my-portfolio-bids-loading-message,
.my-portfolio-bids-error-message {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--mpb-text-secondary) !important;
}

.my-portfolio-bids-error-message {
    color: var(--mpb-accent) !important;
}

/* Filter Container */
.my-portfolio-bids-filter-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--mpb-card-bg);
    border: 1px solid var(--mpb-border);
    border-radius: 8px;
    width: 100%;
}

.my-portfolio-bids-filter-section,
.my-portfolio-bids-search-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.my-portfolio-bids-filter-section label,
.my-portfolio-bids-search-section label {
    color: var(--mpb-text-primary);
    font-weight: normal;
}

.my-portfolio-bids-filter-section select,
.my-portfolio-bids-search-section input {
    width: 100%;
    background-color: var(--mpb-input-bg);
    color: var(--mpb-text-primary);
    border: 1px solid var(--mpb-border);
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    height: 40px;
}

/* Search Section */
.my-portfolio-bids-search-section {
    position: relative;
}

/* Suggestions List */
.my-portfolio-bids-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--mpb-card-bg);
    border: 1px solid var(--mpb-border);
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-portfolio-bids-suggestions-list li {
    padding: 10px 12px;
    cursor: pointer;
    color: var(--mpb-text-primary);
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--mpb-border);
}

.my-portfolio-bids-suggestions-list li:last-child {
    border-bottom: none;
}

.my-portfolio-bids-suggestions-list li:hover {
    background-color: var(--mpb-hover-bg);
}

/* Portfolio Bids Table */
.my-portfolio-bids-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
    background-color: var(--mpb-card-bg) !important;
}

.my-portfolio-bids-table th,
.my-portfolio-bids-table td {
    text-align: center;
    padding: 8px 5px;
    border: 1px solid var(--mpb-border) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--mpb-text-primary) !important;
}

.my-portfolio-bids-table th {
    background-color: var(--mpb-card-bg) !important;
    color: var(--mpb-text-primary) !important;
    cursor: pointer;
    position: relative;
    white-space: normal;
    word-wrap: break-word;
}

.my-portfolio-bids-table tbody tr:nth-of-type(even) {
    background-color: var(--mpb-card-bg) !important;
}

.my-portfolio-bids-table tbody tr:nth-of-type(odd) {
    background-color: var(--mpb-background) !important;
}

.my-portfolio-bids-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--mpb-border) !important;
}

/* Sort Icons */
.my-portfolio-bids-table th svg {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 1em;
    color: var(--mpb-text-primary) !important;
}

/* Name Link */
.my-portfolio-bids-name-link {
    color: var(--mpb-text-primary);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
}

.my-portfolio-bids-name-link:hover {
    color: var(--mpb-accent);
}

.my-portfolio-bids-name-link:hover .my-portfolio-bids-inline-icon {
    transform: translateX(2px);
    transition: transform 0.2s ease-in-out;
}

.my-portfolio-bids-inline-icon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    color: var(--mpb-accent);
    vertical-align: middle;
}

/* Bids Used Column */
.my-portfolio-bids-bid-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    white-space: normal;
}

.my-portfolio-bids-source-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    flex-shrink: 0;
    color: var(--mpb-text-primary) !important;
}

.my-portfolio-bids-bid-text {
    display: inline-block;
    word-break: break-word;
    color: var(--mpb-text-primary) !important;
}

.my-portfolio-bids-table td.my-portfolio-bids-bids-used {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
}

/* Adjust Column Widths */
.my-portfolio-bids-table th.my-portfolio-bids-token-no,
.my-portfolio-bids-table td.my-portfolio-bids-token-no {
    width: 60px;
}

.my-portfolio-bids-table th.my-portfolio-bids-card-name,
.my-portfolio-bids-table td.my-portfolio-bids-card-name {
    width: 200px;
}

.my-portfolio-bids-table th.my-portfolio-bids-szn,
.my-portfolio-bids-table td.my-portfolio-bids-szn {
    width: 50px;
}

.my-portfolio-bids-table th.my-portfolio-bids-seized-count,
.my-portfolio-bids-table td.my-portfolio-bids-seized-count {
    width: 100px;
}

.my-portfolio-bids-table th.my-portfolio-bids-valuation,
.my-portfolio-bids-table td.my-portfolio-bids-valuation {
    width: 150px;
}

.my-portfolio-bids-table th.my-portfolio-bids-bids-used,
.my-portfolio-bids-table td.my-portfolio-bids-bids-used {
    width: 220px;
}

/* Download Button */
.my-portfolio-bids-download-button,
.my-portfolio-bids-green-button {
    display: none;
}

/* Explanation Box */
.my-portfolio-bids-explanation-box {
    border: 1px solid var(--mpb-border) !important;
    border-radius: 8px;
    padding: 15px;
    margin: 20px auto;
    background-color: var(--mpb-card-bg) !important;
    color: var(--mpb-text-secondary) !important;
    width: 100%;
    box-sizing: border-box;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .my-portfolio-bids-filter-container {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .my-portfolio-bids-filter-section,
    .my-portfolio-bids-search-section {
        width: 100%;
    }

    .my-portfolio-bids-filter-section select,
    .my-portfolio-bids-search-section input {
        width: 100%;
    }

    .my-portfolio-bids-table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .my-portfolio-bids-table th,
    .my-portfolio-bids-table td {
        font-size: 12px;
        min-width: 100px;
    }

    .my-portfolio-bids-table th.my-portfolio-bids-card-name,
    .my-portfolio-bids-table td.my-portfolio-bids-card-name {
        min-width: 150px;
    }

    .my-portfolio-bids-table th.my-portfolio-bids-bids-used,
    .my-portfolio-bids-table td.my-portfolio-bids-bids-used {
        min-width: 200px;
        white-space: normal;
    }

    .my-portfolio-bids-bid-item {
        flex-wrap: wrap;
    }
}

/* Records count */
.my-portfolio-bids-records-count {
    text-align: right;
    margin: 10px 0;
    color: var(--mpb-text-secondary);
}

/* Pagination */
.my-portfolio-bids-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.my-portfolio-bids-pagination li {
    margin: 0 5px;
}

.my-portfolio-bids-pagination li a {
    color: var(--mpb-text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--mpb-border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--mpb-card-bg);
}

.my-portfolio-bids-pagination li a:hover {
    background-color: var(--mpb-hover-bg);
}

.my-portfolio-bids-pagination .active a {
    background-color: var(--mpb-accent);
    color: var(--mpb-text-primary);
}

.my-portfolio-bids-pagination .disabled a {
    color: var(--mpb-text-secondary);
    border-color: var(--mpb-border);
    cursor: not-allowed;
}

.my-portfolio-bids-pagination .break a {
    border: none;
    cursor: default;
    background-color: transparent;
}
