@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.nft-collections-container {
    padding: 2rem;
    color: #ffffff;
    background-color: #000000;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.nft-collections-container h1 {
    text-align: center;
    color: var(--nft-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.nft-content {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.nft-content p {
    margin-bottom: 1.5rem;
    color: #cccccc;
}

.purposes-section {
    margin: 2rem 0;
}

.purposes-section ul {
    list-style-type: none;
    padding-left: 1.5rem;
}

.purposes-section li {
    margin: 1rem 0;
    position: relative;
    color: #cccccc;
}

.purposes-section li:before {
    content: "•";
    color: #ff0000;
    position: absolute;
    left: -1.5rem;
}

.collection-section {
    margin: 3rem 0;
    padding: 2rem;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
}

.collection-section h2 {
    color: #ff0000;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.collection-details {
    margin: 2rem 0;
}

.collection-image {
    margin: 2rem 0;
    text-align: center;
}

.collection-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.collection-image img:hover {
    transform: scale(1.02);
}

.coming-soon {
    text-align: center;
    font-style: italic;
    color: #888;
}

.marketplace-links {
    margin-top: 2rem;
    text-align: center;
}

.marketplace-links a {
    color: #ff0000;
    text-decoration: none;
    transition: color 0.3s ease;
}

.marketplace-links a:hover {
    color: #ff3333;
    text-decoration: underline;
}

.accent-text {
    display: none;
} 