/* Navbar.css */

:root {
  --background: #000;
  --card-bg: #0a0a0a;
  --text-primary: #fff;
  --text-secondary: #a0a0a0;
  --accent: #ff0000;
  --border: #333;
  --hover-bg: #111;
}

.navbar {
  background-color: var(--card-bg);
  border-bottom: 1px solid var(--border);
  position: sticky;
  top: 0;
  z-index: 999;
}

.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 10px 15px;
}

.menu-icon {
  display: none;
  font-size: 1.8em;
  cursor: pointer;
  color: var(--text-primary);
}

.menu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu li {
  position: relative;
  margin: 0 15px;
}

.menu li a,
.menu li span,
.menu li button {
  text-decoration: none;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  padding: 10px 15px;
  display: block;
  background: none;
  border: none;
  white-space: nowrap;
}

.menu li a:hover,
.menu li span:hover,
.menu li button:hover {
  color: var(--accent);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--card-bg);
  padding: 0;
  list-style-type: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  width: auto;
  border: 1px solid var(--border);
  border-radius: 4px;
}

.menu li.dropdown:hover > .dropdown-menu,
.menu li.dropdown.active > .dropdown-menu {
  display: block;
}

.dropdown-menu li a {
  padding: 10px 20px;
  white-space: nowrap;
  display: block;
  color: var(--text-primary);
}

.dropdown-menu li a:hover {
  background-color: var(--hover-bg);
  color: var(--accent);
}

/* Active Link Styling */
.menu li a.active-link,
.menu li span.active-link {
  color: var(--accent);
  font-weight: bold;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-container {
    justify-content: space-between;
  }

  .menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: -100%;
    width: 100%;
    background-color: var(--card-bg);
    transition: left 0.3s ease;
    border-top: 1px solid var(--border);
  }

  .menu.active {
    left: 0;
  }

  .menu li {
    margin: 0;
    border-bottom: 1px solid var(--border);
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    background-color: var(--background);
    border: none;
    border-top: 1px solid var(--border);
  }

  .menu li.dropdown > span::after {
    content: ' ▼';
    font-size: 0.6em;
    margin-left: 5px;
    color: var(--text-secondary);
  }

  .menu li.dropdown.active > span::after {
    content: ' ▲';
  }
}

/* Add submenu styles */
.submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 4px;
    min-width: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.menu li:hover > .submenu {
    display: block;
}

.submenu li {
    margin: 0;
    padding: 0;
}

.submenu a {
    padding: 10px 15px;
    display: block;
    white-space: nowrap;
}

/* Mobile adjustments for submenu */
@media screen and (max-width: 768px) {
    .submenu {
        position: static;
        border: none;
        box-shadow: none;
        margin-left: 20px;
        min-width: auto;
    }

    .menu li:hover > .submenu {
        display: none;
    }

    .menu li.active > .submenu {
        display: block;
    }
}
