/* InsightsComponent.css */

:root {
    --ins-background: #000;
    --ins-card-bg: #0a0a0a;
    --ins-text-primary: #fff;
    --ins-text-secondary: #a0a0a0;
    --ins-accent: #ff0000;
    --ins-border: #333;
    --ins-hover-bg: #111;
    --ins-input-bg: #1a1a1a;
}

.insights-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--ins-background) !important;
    color: var(--ins-text-primary) !important;
}
  
.main-header {
    text-align: center;
    color: var(--ins-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Remove accent text */
.accent-text {
    display: none;
}

.section-header {
    color: var(--ins-text-primary) !important;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--ins-border);
}

.chart-section {
    margin-bottom: 60px;
    padding: 25px;
    background-color: var(--ins-card-bg);
    border: 1px solid var(--ins-border);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.chart-section-title {
    color: var(--ins-text-primary) !important;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--ins-border);
}

.chart-container {
    background-color: var(--ins-card-bg);
    padding: 20px;
    border-radius: 8px;
    height: 400px;
}

.chart-notes {
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid var(--ins-border);
    font-size: 0.9em;
    color: var(--ins-text-secondary);
}

.chart-notes p {
    margin-bottom: 10px;
}

.chart-notes ul {
    padding-left: 20px;
    margin-bottom: 0;
}

.chart-notes li {
    margin-bottom: 5px;
    word-break: break-all;
}

.chart-notes strong {
    color: var(--ins-text-primary);
}
  
.metrics-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
    background-color: var(--ins-card-bg) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--ins-border) !important;
    border-radius: 10px;
    overflow: hidden;
}
  
.metrics-table th,
.metrics-table td {
    padding: 15px;
    border: 1px solid var(--ins-border) !important;
    color: var(--ins-text-primary) !important;
}
  
.metrics-table th {
    background-color: var(--ins-card-bg) !important;
    color: var(--ins-text-primary) !important;
    text-align: left;
    font-size: 1.1em;
}
  
.metrics-table td {
    text-align: left;
}
  
.metrics-table tr.even-row {
    background-color: var(--ins-card-bg) !important;
}

.metrics-table tr:not(.even-row) {
    background-color: var(--ins-background) !important;
}
  
.metric-name-cell {
    font-weight: bold;
    background-color: var(--ins-card-bg) !important;
    width: 20%;
}
  
.pptdh-values div {
    margin-bottom: 5px;
    color: var(--ins-text-primary) !important;
}
  
/* Colored Arrows - keeping these colors for visual indication */
.arrow-up {
    color: #00ff00 !important; /* Brighter green for visibility on dark background */
}
  
.arrow-down {
    color: #ff0000 !important; /* Keeping red for down arrows */
}
  
/* Contact Section */
.contact-section {
    text-align: center;
    margin-top: 20px;
}
  
.contact-section p {
    margin-bottom: 10px;
    color: var(--ins-text-secondary) !important;
}
  
/* Contact Button Styling */
.contact-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--ins-accent) !important;
    color: var(--ins-text-primary) !important;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
  
.contact-button:hover {
    background-color: var(--ins-hover-bg) !important;
}
  
/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .metrics-table th,
    .metrics-table td {
        padding: 8px;
    }
  
    .metric-name-cell {
        width: 30%;
    }
  
    .contact-button {
        padding: 8px 16px;
    }
}
  
.comparison-line {
    white-space: nowrap;
  }
  