/* Variables */
:root {
  --background: #000;
  --card-bg: #0a0a0a;
  --text-primary: #fff;
  --text-secondary: #a0a0a0;
  --accent: #ff0000;
  --border: #333;
  --hover-bg: #111;
  --total-row-bg: #1a1a1a;
  --input-bg: #1a1a1a;
}

/* Container */
.sales-summary-container {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--background);
    color: var(--text-primary);
}

/* Headers */
.sales-summary-main-header {
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Remove accent-text class */
.accent-text {
    display: none;
}

/* Filters form */
.sales-summary-filters-form {
    margin-bottom: 20px;
    border: 1px solid var(--border);
    border-radius: 8px;
    background-color: var(--card-bg);
    width: 100%;
}

.sales-summary-filters-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 0;
    padding: 20px;
}

.sales-summary-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.sales-summary-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--text-primary);
}

.sales-summary-filter-item select,
.sales-summary-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--input-bg);
    color: var(--text-primary);
}

/* Search container and suggestions */
.sales-summary-search-container {
    position: relative;
    width: 100%;
}

.sales-summary-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 4px;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sales-summary-suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--text-primary);
    transition: background-color 0.2s ease;
}

.sales-summary-suggestions-list li:hover {
    background-color: var(--hover-bg);
}

/* Info row */
.sales-summary-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
}

.sales-summary-records-info,
.sales-summary-last-updated {
    font-size: 1em;
    color: var(--text-secondary);
    white-space: nowrap;
}

/* Time Period Selector */
.sales-summary-time-period-selector {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
}

.sales-summary-time-period-selector label {
    font-weight: bold;
    color: var(--text-primary);
}

.sales-summary-time-period-selector select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--input-bg);
    color: var(--text-primary);
}

/* Filter Container */
.sales-summary-filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.sales-summary-filter-container div {
    display: flex;
    align-items: center;
    position: relative;
}

.sales-summary-filter-container label {
    margin-right: 5px;
    color: var(--text-primary);
}

.sales-summary-filter-container select,
.sales-summary-filter-container input[type="text"] {
    padding: 5px;
    border: 1px solid var(--border);
    border-radius: 4px;
    background-color: var(--input-bg);
    color: var(--text-primary);
}

.sales-summary-filter-container input[type="text"] {
    width: 200px;
    height: 30px;
    box-sizing: border-box;
}

/* Download Records Container */
.sales-summary-download-records-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;
}

.sales-summary-download-button {
    display: none;
}

/* Spinner */
.sales-summary-spinner {
    border: 8px solid var(--border);
    border-top: 8px solid var(--accent);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 50px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Table Wrapper */
.sales-summary-table-wrapper {
    width: 100%;
    overflow-x: auto;
}

/* Tables */
.sales-summary-centered-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--card-bg);
    border-radius: 8px;
    overflow: hidden;
}

.sales-summary-centered-table th,
.sales-summary-centered-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--border);
}

.sales-summary-centered-table th {
    background-color: var(--card-bg);
    color: var(--text-primary);
    cursor: pointer;
}

.sales-summary-centered-table tbody tr:nth-of-type(even) {
    background-color: var(--card-bg);
}

.sales-summary-centered-table tbody tr:nth-of-type(odd) {
    background-color: var(--background);
}

.sales-summary-centered-table tbody tr:hover {
    background-color: var(--hover-bg);
}

.sales-summary-centered-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--border);
}

.sales-summary-full-width-table {
    min-width: 1000px;
}

/* Tooltip Styles */
.sales-summary-tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
}

.sales-summary-tooltip .sales-summary-tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: var(--card-bg);
    color: var(--text-primary);
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid var(--border);
}

.sales-summary-tooltip:hover .sales-summary-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.sales-summary-tooltip .sales-summary-tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--border) transparent;
}

/* Inline class for icons */
.sales-summary-inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    color: var(--text-secondary);
}

/* Pagination */
.sales-summary-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
}

.sales-summary-pagination li {
    display: inline-block;
}

.sales-summary-pagination a {
    background-color: var(--card-bg);
    border: 1px solid var(--border) !important;
    color: var(--text-primary) !important;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 0.875rem;
}

.sales-summary-pagination a:hover {
    background-color: var(--hover-bg);
}

.sales-summary-pagination .active a {
    background-color: var(--accent) !important;
    color: var(--text-primary) !important;
}

.sales-summary-pagination .disabled a {
    cursor: not-allowed;
    opacity: 0.5;
}

.sales-summary-pagination .break a {
    border-color: transparent;
    padding: 8px 5px;
}

/* Name Link */
.sales-summary-name-link {
    color: var(--text-primary) !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.sales-summary-name-link:hover {
    color: var(--text-primary) !important;
}

/* Chevron Icon */
.sales-summary-chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.sales-summary-name-link:hover .sales-summary-chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Remove old Info Icon styles */
.sales-summary-inline-icon {
    display: none;
}

/* Responsive design */
@media screen and (max-width: 1200px) {
    .sales-summary-filters-row {
        flex-wrap: wrap;
    }
    
    .sales-summary-filter-item {
        flex: 1 1 calc(50% - 10px);
        min-width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .sales-summary-filters-row {
        flex-direction: column;
    }
    
    .sales-summary-filter-item {
        width: 100%;
    }

    .sales-summary-info-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}
