@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* NotificationsComponent.css */

:root {
    --notif-background: #000;
    --notif-card-bg: #0a0a0a;
    --notif-text-primary: #fff;
    --notif-text-secondary: #a0a0a0;
    --notif-accent: #ff0000;
    --notif-border: #333;
    --notif-hover-bg: #111;
    --notif-input-bg: #1a1a1a;
}

.notifications-container {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    background-color: var(--notif-background) !important;
    color: var(--notif-text-primary) !important;
}

.notifications-container h1 {
    text-align: center;
    color: var(--notif-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.notifications-container h2 {
    font-size: 2em;
    color: var(--notif-text-primary) !important;
    margin-top: 40px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.notifications-container p {
    font-size: 1.1em;
    color: var(--notif-text-secondary) !important;
    margin-bottom: 20px;
    line-height: 1.6;
}

.image-item {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid var(--notif-border) !important;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: var(--notif-card-bg) !important;
}

.image-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
}

.image-item img {
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.image-item img:hover {
    transform: scale(1.02);
}

/* Green Button Styling */
.green-button {
    background-color: var(--notif-accent) !important;
    color: var(--notif-text-primary) !important;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin-top: 20px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.green-button:hover {
    background-color: var(--notif-hover-bg) !important;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
    .notifications-container {
        padding: 30px 15px;
    }

    .notifications-container h1 {
        font-size: 2.2em;
    }

    .notifications-container h2 {
        font-size: 1.8em;
    }

    .notifications-container p {
        font-size: 1em;
    }

    .image-item {
        max-width: 500px;
    }
}

@media screen and (max-width: 768px) {
    .notifications-container {
        padding: 20px 10px;
    }

    .notifications-container h1 {
        font-size: 2em;
    }

    .notifications-container h2 {
        font-size: 1.5em;
    }

    .notifications-container p {
        font-size: 0.95em;
    }

    .image-item {
        max-width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .notifications-container h1 {
        font-size: 1.8em;
    }

    .notifications-container h2 {
        font-size: 1.3em;
    }

    .notifications-container p {
        font-size: 0.9em;
    }
}

.notifications-container {
    text-align: center;
}

.notification-list {
    display: inline-block;
    text-align: left;
    color: var(--notif-text-primary) !important;
}

.notification-list p {
    margin: 5px 0;
    color: var(--notif-text-secondary) !important;
}

/* Remove accent-text class */
.accent-text {
    display: none;
}
