/* HodlerSupplyEnhancedComponent.css */

:root {
    --hodler-background: #000;
    --hodler-card-bg: #0a0a0a;
    --hodler-text-primary: #fff;
    --hodler-text-secondary: #a0a0a0;
    --hodler-accent: #ff0000;
    --hodler-border: #333;
    --hodler-hover-bg: #111;
    --hodler-input-bg: #1a1a1a;
}

/* Container */
.hodler-enhanced-container {
    width: 100%;
    margin: 10px auto;
    padding: 0 15px;
    background-color: var(--hodler-background);
    color: var(--hodler-text-primary);
}

/* Title styling */
.hodler-enhanced-title {
    text-align: center;
    color: var(--hodler-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

/* Remove enhanced text styling */
.enhanced-text {
    display: none;
}

/* Filters form */
.hodler-enhanced-filters-form {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.hodler-enhanced-filters-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.hodler-enhanced-filter-item {
    flex: 1;
    min-width: 200px;
    position: relative;
}

.hodler-enhanced-filter-item label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--hodler-text-primary);
}

.hodler-enhanced-filter-item select,
.hodler-enhanced-filter-item input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--hodler-border);
    border-radius: 4px;
    background-color: var(--hodler-input-bg);
    color: var(--hodler-text-primary);
}

/* Search container and suggestions */
.hodler-enhanced-search-container {
    position: relative;
    width: 100%;
}

.hodler-enhanced-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--hodler-card-bg);
    border: 1px solid var(--hodler-border);
    border-radius: 4px;
    margin-top: 4px;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hodler-enhanced-suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--hodler-text-primary);
    transition: background-color 0.2s ease;
}

.hodler-enhanced-suggestions-list li:hover {
    background-color: var(--hodler-hover-bg);
}

.hodler-enhanced-search-input {
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--hodler-border);
    border-radius: 4px;
    background-color: var(--hodler-input-bg);
    color: var(--hodler-text-primary);
}

.hodler-enhanced-search-input::placeholder {
    color: var(--hodler-text-secondary);
    opacity: 0.7;
}

/* Action buttons row */
.hodler-enhanced-action-buttons-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.hodler-enhanced-buttons-group {
    display: flex;
    gap: 10px;
}

/* Info row styling */
.hodler-enhanced-info-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--hodler-border);
}

.hodler-enhanced-explanations {
    flex: 1;
    margin: 0;
}

.hodler-enhanced-explanations p {
    margin: 0;
    color: var(--hodler-text-secondary);
}

.hodler-enhanced-explanations p + p {
    margin-top: 5px;
}

.hodler-enhanced-records-count {
    text-align: right;
    white-space: nowrap;
    padding-top: 5px;
}

.hodler-enhanced-records-count span {
    color: var(--hodler-text-secondary);
}

/* Table wrapper */
.hodler-enhanced-table-wrapper {
    margin-top: 10px;
}

/* Tables */
.hodler-enhanced-centered-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--hodler-card-bg) !important;
}

.hodler-enhanced-centered-table th,
.hodler-enhanced-centered-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--hodler-border) !important;
    color: var(--hodler-text-primary) !important;
}

.hodler-enhanced-centered-table th {
    background-color: var(--hodler-card-bg) !important;
    color: var(--hodler-text-primary) !important;
    cursor: pointer;
    position: relative;
}

.hodler-enhanced-centered-table tbody tr:nth-of-type(even) {
    background-color: var(--hodler-card-bg) !important;
}

.hodler-enhanced-centered-table tbody tr:nth-of-type(odd) {
    background-color: var(--hodler-background) !important;
}

.hodler-enhanced-centered-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--hodler-border) !important;
}

/* Sort Icons */
.hodler-enhanced-sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--hodler-text-primary);
}

/* Optional: Change cursor to pointer on sortable columns */
.hodler-enhanced-centered-table th.sortable {
    cursor: pointer;
}

/* Pagination */
.hodler-enhanced-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.hodler-enhanced-pagination button {
    padding: 5px 10px;
    margin: 0 10px;
    border: 1px solid var(--hodler-border) !important;
    background-color: var(--hodler-card-bg) !important;
    color: var(--hodler-text-primary) !important;
    cursor: pointer;
    border-radius: 4px;
}

.hodler-enhanced-pagination button:hover:not(:disabled) {
    background-color: var(--hodler-hover-bg) !important;
}

.hodler-enhanced-pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: var(--hodler-background) !important;
    color: var(--hodler-text-secondary) !important;
}

.hodler-enhanced-pagination span {
    font-size: 1em;
    color: var(--hodler-text-secondary) !important;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .hodler-enhanced-table-wrapper {
        overflow-x: auto;
    }

    .hodler-enhanced-centered-table th,
    .hodler-enhanced-centered-table td {
        padding: 5px;
    }

    .hodler-enhanced-pagination button {
        margin: 0 5px;
    }

    .hodler-enhanced-filters-row {
        flex-direction: column;
    }
    
    .hodler-enhanced-filter-item {
        width: 100%;
    }
}

/* Update name link styles */
.hodler-enhanced-name-link {
    color: var(--hodler-text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.hodler-enhanced-name-link:hover {
    color: var(--hodler-text-primary);
}

.hodler-enhanced-chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--hodler-accent);
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.hodler-enhanced-name-link:hover .hodler-enhanced-chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Remove the old info icon styles */
.hodler-enhanced-inline-icon {
    display: none;
}

/* Mobile improvements for info text */
@media screen and (max-width: 768px) {
    .hodler-enhanced-info-toggle {
        display: flex !important; /* Force display on mobile */
    }

    .hodler-enhanced-info-row {
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
    }

    .hodler-enhanced-explanations {
        display: none;
        background-color: var(--hodler-card-bg);
        padding: 15px;
        border-radius: 4px;
        border: 1px solid var(--hodler-border);
        margin-top: 10px;
    }

    .hodler-enhanced-explanations.show {
        display: block;
    }

    .hodler-enhanced-info-toggle:hover {
        background-color: var(--hodler-hover-bg);
    }

    .hodler-enhanced-info-toggle .info-icon {
        width: 16px;
        height: 16px;
        color: var(--hodler-accent);
    }

    .hodler-enhanced-records-count {
        text-align: left;
        padding-top: 10px;
        border-top: 1px solid var(--hodler-border);
        margin-top: 10px;
    }
}

.hodler-enhanced-info-toggle {
    display: none; /* Hidden by default */
    background-color: var(--hodler-card-bg);
    border: 1px solid var(--hodler-border);
    color: var(--hodler-text-primary);
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
}
