/* HodlerSupplyComponent.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

:root {
    --background: #000;
    --card-bg: #0a0a0a;
    --text-primary: #fff;
    --text-secondary: #a0a0a0;
    --accent: #ff0000;
    --border: #333;
    --hover-bg: #111;
    --total-row-bg: #1a1a1a;
    --input-bg: #1a1a1a;
    --max-width: 1400px;
}

/* Container */
.hodler-supply-container {
    background-color: var(--background);
    color: var(--text-primary);
    min-height: 100vh;
    padding: 1.5rem;
    width: 100%;
    overflow-x: auto;
}
  
/* Headers */
.hodler-main-header {
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}
  
/* Filter Container */
.hodler-filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 8px;
}
  
.hodler-filter-container > div {
    flex: 1;
    min-width: 200px;
}
  
.hodler-filter-container label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-primary);
    font-weight: bold;
}
  
.hodler-filter-container select,
.hodler-search-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border) !important;
    border-radius: 4px;
    background-color: var(--input-bg) !important;
    color: var(--text-primary) !important;
    font-size: 14px;
}

/* Search container and suggestions */
.hodler-search-container {
    position: relative;
    width: 100%;
}

.hodler-suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 4px;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hodler-suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
    color: var(--text-primary);
    transition: background-color 0.2s ease;
}

.hodler-suggestions-list li:hover {
    background-color: var(--hover-bg);
}

.hodler-search-input::placeholder {
    color: var(--text-secondary);
    opacity: 0.7;
}
  
/* Explanations and Record Count Container */
.hodler-explanations-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
}

.hodler-explanations {
    margin: 0;
    font-size: 0.9em;
    color: var(--text-secondary);
}

.hodler-explanations p {
    margin: 5px 0;
    color: var(--text-secondary);
}

.record-count {
    color: var(--text-secondary);
    font-size: 0.9em;
}
  
/* Table Wrapper */
.hodler-table-wrapper {
    width: 100%;
    overflow-x: auto;
}
  
/* Tables */
.hodler-centered-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--card-bg) !important;
}
  
.hodler-centered-table th,
.hodler-centered-table td {
    text-align: center;
    padding: 8px 10px;
    border: 1px solid var(--border) !important;
    color: var(--text-primary) !important;
}
  
.hodler-centered-table th {
    background-color: var(--card-bg) !important;
    color: var(--text-primary) !important;
    cursor: pointer;
    position: relative;
}
  
.hodler-centered-table tbody tr:nth-of-type(even) {
    background-color: var(--card-bg) !important;
}

.hodler-centered-table tbody tr:nth-of-type(odd) {
    background-color: var(--background) !important;
}
  
.hodler-centered-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--border) !important;
}
  
/* Sort Icons */
.hodler-centered-table th svg {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 0.8em;
    color: var(--text-primary) !important;
}
  
/* Optional: Change cursor to pointer on sortable columns */
.hodler-centered-table th {
    cursor: pointer;
}
  
/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .hodler-filter-container {
        flex-direction: column;
    }
  
    .hodler-filter-container > div {
        width: 100%;
    }
  
    .hodler-explanations {
        margin-top: 10px;
        text-align: left;
        padding: 0 15px;
    }

    .hodler-main-header {
        font-size: 2em;
    }
}
  
/* Accent Text */
.accent-text {
    display: none;
}

/* Name Link Styles */
.hodler-name-link {
    color: var(--text-primary);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.hodler-name-link:hover {
    color: var(--text-primary);
}

.hodler-chevron-icon {
    width: 14px;
    height: 14px;
    color: var(--accent) !important;
    opacity: 0.7;
    transition: transform 0.2s ease-in-out;
}

.hodler-name-link:hover .hodler-chevron-icon {
    opacity: 1;
    transform: translateX(2px);
}

/* Remove old link styles */
.table-link {
    display: none;
}
  
/* Update pagination styles to match PebblesMainComponent */
.hodler-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.hodler-pagination li {
    margin: 0 5px;
}

.hodler-pagination li a {
    color: var(--text-primary);
    padding: 8px 12px;
    text-decoration: none;
    border: 1px solid var(--border);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--card-bg);
    transition: background-color 0.2s ease;
}

.hodler-pagination li.disabled a {
    cursor: not-allowed;
    opacity: 0.5;
}

.hodler-pagination li.break a {
    border-color: transparent;
    padding: 8px 5px;
}

.hodler-pagination li a:hover:not([disabled]) {
    background-color: var(--hover-bg);
}

.hodler-pagination li.active a {
    background-color: var(--accent);
    border-color: var(--accent);
    color: var(--text-primary);
}

.hodler-pagination li.previous a,
.hodler-pagination li.next a {
    padding: 8px 16px;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
    .hodler-pagination {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }

    .hodler-pagination li a {
        padding: 6px 10px;
        font-size: 12px;
    }

    .hodler-pagination li.previous a,
    .hodler-pagination li.next a {
        padding: 6px 12px;
    }
}
  
/* Update icon styles to match PricesComponent */
.inline {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: var(--text-primary);
}

.hodler-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
  