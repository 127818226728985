/* SalesAnalyticsComponent.css */
:root {
    --sa-background: #000;
    --sa-card-bg: #0a0a0a;
    --sa-text-primary: #fff;
    --sa-text-secondary: #a0a0a0;
    --sa-accent: #ff0000;
    --sa-border: #333;
    --sa-hover-bg: #111;
    --sa-input-bg: #1a1a1a;
}

.sales-analytics {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--sa-background);
    color: var(--sa-text-primary);
}

.sales-analytics .title {
    text-align: center;
    color: var(--sa-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.sales-analytics .description {
    text-align: center;
    color: var(--sa-text-secondary);
    margin-bottom: 20px;
    font-size: 1em;
    line-height: 1.5;
}

.accent-text {
    display: none;
}

.custom-date-selector {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.custom-date-selector label {
    font-weight: bold;
    margin-right: 5px;
    color: var(--sa-text-primary);
}

.custom-date-selector input[type="date"] {
    padding: 5px;
    border: 1px solid var(--sa-border);
    border-radius: 4px;
    background-color: var(--sa-input-bg);
    color: var(--sa-text-primary);
}

.custom-date-selector button {
    padding: 6px 12px;
    background-color: var(--sa-accent);
    color: var(--sa-text-primary);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-date-selector button:disabled {
    background-color: var(--sa-text-secondary);
    cursor: not-allowed;
}

.custom-date-selector button:hover:not(:disabled) {
    background-color: var(--sa-hover-bg);
}

.error-message {
    text-align: center;
    color: var(--sa-accent);
    margin-bottom: 10px;
}

.sales-analytics-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: var(--sa-card-bg);
    border: 1px solid var(--sa-border);
    border-radius: 8px;
}

.sales-analytics-table th,
.sales-analytics-table td {
    border: 1px solid var(--sa-border);
    text-align: center;
    padding: 12px;
}

.sales-analytics-table th {
    background-color: var(--sa-card-bg);
    color: var(--sa-text-primary);
    font-weight: bold;
}

.sales-analytics-table .metric-label {
    text-align: left;
    font-weight: bold;
    background-color: var(--sa-background);
    color: var(--sa-text-primary);
}

.sales-analytics.loading,
.sales-analytics.error {
    text-align: center;
    font-size: 1.2em;
    color: var(--sa-text-primary);
    padding: 50px 0;
}

.download-section {
    display: none;
}

.download-csv-btn {
    display: none;
}

.update-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.update-info {
    margin-top: 10px;
    font-size: 14px;
    color: var(--sa-text-secondary);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .sales-analytics-table th,
    .sales-analytics-table td {
        padding: 8px;
        font-size: 14px;
    }

    .sales-analytics .title {
        font-size: 1.5em;
    }

    .sales-analytics .description {
        font-size: 0.9em;
    }

    .custom-date-selector {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    .sales-analytics-table th,
    .sales-analytics-table td {
        padding: 6px;
        font-size: 12px;
    }

    .sales-analytics .title {
        font-size: 1.2em;
    }

    .sales-analytics .description {
        font-size: 0.8em;
    }
}
