.artist-306-container {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--background);
    color: var(--text-primary);
}

.artist-306-title {
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 30px;
    font-size: 2.5em;
}

.artist-306-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.artist-306-section {
    background-color: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 20px;
}

.artist-306-section h2 {
    margin-bottom: 15px;
    font-size: 1.8em;
}

.artist-306-section p {
    color: var(--text-primary);
    line-height: 1.6;
    font-size: 1.1em;
}

/* About Section Styles */
.artist-306-about {
    display: flex;
    align-items: center;
    gap: 30px;
}

.artist-306-about-image {
    flex: 0 0 200px;
}

.artist-306-about-image img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 200px;
}

.artist-306-about-text {
    flex: 1;
}

/* Meme Card Styles */
.artist-306-meme-header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
}

.artist-306-meme-cover {
    flex: 0 0 200px;
}

.artist-306-meme-cover img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 200px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.artist-306-meme-cover img:hover {
    transform: translateY(-5px);
}

.artist-306-meme-intro {
    flex: 1;
}

.artist-306-meme-description {
    margin-bottom: 20px;
    font-style: italic;
    color: var(--text-secondary);
}

.artist-306-view-now-button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.artist-306-view-now-button:hover {
    background-color: darkred;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Seal Image Styles */
.artist-306-seal-image {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.artist-306-seal-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* OpenSea Link Styles */
.artist-306-opensea-link {
    text-align: center;
    margin: 20px 0;
}

.artist-306-opensea-link a {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    background-color: var(--card-bg);
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid var(--border);
    transition: all 0.3s ease;
}

.artist-306-opensea-link a:hover {
    transform: translateY(-2px);
    border-color: #2081E2; /* OpenSea blue */
    box-shadow: 0 4px 8px rgba(32, 129, 226, 0.3);
}

.artist-306-opensea-link .opensea-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.artist-306-cta-container {
    text-align: center;
    margin: 20px 0;
}

.artist-306-cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: red;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.artist-306-cta-button:hover {
    background-color: darkred;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.artist-306-meme-poem {
    margin: 30px auto;
    max-width: 600px;
    text-align: center;
    font-style: italic;
    line-height: 1.8;
    padding: 20px;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}

.artist-306-meme-description-full {
    margin: 20px auto;
    max-width: 800px;
    line-height: 1.8;
}

.artist-306-meme-description-full p {
    text-align: justify;
}

/* Gallery Styles */
.artist-306-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.artist-306-gallery-item {
    background-color: var(--artist-background);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.artist-306-gallery-item:hover {
    transform: translateY(-5px);
}

.artist-306-gallery-item img,
.artist-306-gallery-item video {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
}

.artist-306-caption {
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    color: var(--artist-text-secondary);
}

.artist-306-caption a {
    color: red;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 0.3s ease;
}

.artist-306-caption a:hover {
    opacity: 0.8;
    text-decoration: underline;
}

/* Meme Card Section Styles */
.artist-306-meme-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 20px;
}

.artist-306-meme-video {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.artist-306-meme-video video.meme-video {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: black;
}

/* Links Styles */
.artist-306-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
    flex-wrap: wrap;
}

.artist-306-links a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: var(--card-bg);
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid var(--border);
    transition: all 0.3s ease;
}

.artist-306-links a:hover {
    transform: translateY(-2px);
    border-color: red;
}

.artist-306-links .social-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .artist-306-container {
        padding: 15px;
    }

    .artist-306-title {
        font-size: 2em;
    }

    .artist-306-section h2 {
        font-size: 1.5em;
    }

    .artist-306-gallery {
        grid-template-columns: 1fr;
    }

    .artist-306-meme-content {
        padding: 10px;
    }

    .artist-306-meme-video {
        max-width: 100%;
    }
    
    /* Responsive adjustment for about section */
    .artist-306-about {
        flex-direction: column;
        text-align: center;
    }
    
    .artist-306-about-image {
        margin-bottom: 20px;
    }
    
    .artist-306-about-image img {
        margin: 0 auto;
    }
    
    /* Responsive adjustment for meme header */
    .artist-306-meme-header {
        flex-direction: column;
        text-align: center;
    }
    
    .artist-306-meme-cover {
        margin-bottom: 20px;
    }
    
    .artist-306-meme-cover img {
        margin: 0 auto;
    }
    
    .artist-306-meme-poem {
        padding: 15px 10px;
    }
    
    .artist-306-cta-button {
        padding: 10px 20px;
        font-size: 0.9em;
    }
}

/* Add these styles for collapsible sections */
.collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.collapsible-header:hover {
    opacity: 0.8;
}

.chevron {
    font-size: 0.8em;
    transition: transform 0.3s ease;
    margin-left: 10px;
}

.chevron.expanded {
    transform: rotate(0deg);
}

.chevron:not(.expanded) {
    transform: rotate(-90deg);
}
