/* TokenOwnersComponent.css */

:root {
    --owners-background: #000;
    --owners-card-bg: #0a0a0a;
    --owners-text-primary: #fff;
    --owners-text-secondary: #a0a0a0;
    --owners-accent: #ff0000;
    --owners-border: #333;
    --owners-hover-bg: #111;
    --owners-input-bg: #1a1a1a;
}

.owners-container {
    padding: 20px;
    max-width: 1800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: var(--owners-text-primary) !important;
    background-color: var(--owners-background) !important;
}

/* Header Section */
.token-header {
    text-align: center;
    margin-bottom: 20px;
    color: var(--owners-text-primary) !important;
}

.owners-title {
    text-align: center;
    color: var(--owners-text-primary) !important;
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block !important;
    line-height: 1.2;
    visibility: visible !important;
    opacity: 1 !important;
}

/* Remove old styles */
.token-title {
    display: none;
}

.token-header p {
    font-size: 1.1em;
    color: var(--owners-text-secondary) !important;
}

/* Header Row */
.header-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.back-link {
    text-decoration: none;
    color: var(--owners-accent) !important;
    font-weight: bold;
}

.btn {
    background-color: var(--owners-accent) !important;
    color: var(--owners-text-primary) !important;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 1em;
}

.btn:hover {
    background-color: var(--owners-hover-bg) !important;
}

.owners-container h1 {
    text-align: center;
    margin-bottom: 10px;
    color: var(--owners-text-primary) !important;
}

.owners-container p {
    text-align: center;
    margin-bottom: 20px;
    color: var(--owners-text-secondary) !important;
}

/* Search Container */
.search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

/* Search Box */
.search-box {
    position: relative;
    width: 48%;
}

.search-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid var(--owners-border) !important;
    border-radius: 4px;
    background-color: var(--owners-input-bg) !important;
    color: var(--owners-text-primary) !important;
}

.search-input::placeholder {
    color: var(--owners-text-secondary) !important;
}

.suggestions-list {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: var(--owners-card-bg) !important;
    border: 1px solid var(--owners-border) !important;
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
}

.suggestions-list li {
    padding: 8px;
    cursor: pointer;
    color: var(--owners-text-primary) !important;
}

.suggestions-list li:hover {
    background-color: var(--owners-hover-bg) !important;
}

/* Owners Table */
.owners-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow-x: auto;
    display: block;
    background-color: var(--owners-card-bg) !important;
}

.owners-container th,
.owners-container td {
    border: 1px solid var(--owners-border) !important;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    color: var(--owners-text-primary) !important;
}

.owners-container th {
    background-color: var(--owners-card-bg) !important;
    color: var(--owners-text-primary) !important;
    font-size: 1em;
}

.owners-container tbody tr:nth-child(even) {
    background-color: var(--owners-card-bg) !important;
}

.owners-container tbody tr:nth-child(odd) {
    background-color: var(--owners-background) !important;
}

.owners-container tbody tr:hover {
    background-color: var(--owners-hover-bg) !important;
}

/* Style for the Seize and OpenSea icons */
.marketplace-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    vertical-align: middle;
    filter: invert(1);
}

/* Style for the Seize Profile link */
.profile-link {
    color: var(--owners-accent) !important;
    text-decoration: none;
    font-weight: bold;
}

.profile-link:hover {
    color: var(--owners-text-primary) !important;
    text-decoration: underline;
    cursor: pointer;
}

/* Spinner Styles */
.spinner {
    border: 8px solid var(--owners-card-bg) !important;
    border-top: 8px solid var(--owners-accent) !important;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 100px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive Design for Table Headers */
@media (max-width: 1800px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.95em;
    }
}

@media (max-width: 1600px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.9em;
    }
}

@media (max-width: 1400px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.85em;
    }
}

@media (max-width: 1200px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.8em;
    }
}

@media (max-width: 1024px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.75em;
    }
}

@media (max-width: 768px) {
    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.7em;
    }
}

@media (max-width: 480px) {
    .search-container {
        flex-direction: column;
    }

    .search-box {
        width: 100%;
        margin-bottom: 10px;
    }

    .owners-container table, .owners-container th, .owners-container td {
        font-size: 0.6em;
    }
}

/* Filter Buttons */
.filter-buttons {
    display: flex;
    gap: 10px;
    margin: 20px 0;
    flex-wrap: wrap;
    justify-content: center;
}

.filter-btn {
    padding: 8px 16px;
    border: 1px solid #ff0000;
    border-radius: 4px;
    background-color: transparent;
    color: #a0a0a0;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
}

.filter-btn:hover {
    background-color: #ff0000;
    color: #fff;
}

.filter-btn.active {
    background-color: #ff0000;
    color: #fff;
}

/* Ensure buttons are responsive */
@media (max-width: 768px) {
    .filter-buttons {
        flex-direction: column;
        align-items: stretch;
    }
    
    .filter-btn {
        width: 100%;
        margin: 5px 0;
    }
}

/* Filter Explainer */
.filter-explainer {
    margin: 20px 0;
    padding: 15px;
    background-color: #0a0a0a;
    border: 1px solid #333;
    border-radius: 8px;
}

.filter-explainer p {
    margin: 0;
    line-height: 1.5;
    color: #a0a0a0;
}

.filter-explainer strong {
    color: #fff;
}

/* Token Metadata */
.token-metadata {
    font-size: 1.1em;
    color: var(--owners-text-secondary) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.marketplace-links,
.marketplace-link,
.marketplace-link:hover,
.marketplace-icon {
    display: none;
}
