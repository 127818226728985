/* ContactForm.css */

:root {
    --contact-background: #000;
    --contact-card-bg: #0a0a0a;
    --contact-text-primary: #fff;
    --contact-text-secondary: #a0a0a0;
    --contact-accent: #ff0000;
    --contact-border: #333;
    --contact-hover-bg: #111;
    --contact-input-bg: #1a1a1a;
}

.contact-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: var(--contact-background) !important;
    color: var(--contact-text-primary) !important;
}

.contact-header {
    text-align: center;
    color: var(--contact-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.contact-subtext {
    font-size: 1em;
    margin-bottom: 20px;
    color: var(--contact-text-secondary) !important;
}

.contact-form-container form {
    text-align: left;
}

.contact-form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--contact-text-primary) !important;
}

.contact-form-container input[type="text"],
.contact-form-container input[type="email"],
.contact-form-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--contact-border) !important;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: var(--contact-input-bg) !important;
    color: var(--contact-text-primary) !important;
}

.contact-form-container input[type="text"]::placeholder,
.contact-form-container input[type="email"]::placeholder,
.contact-form-container textarea::placeholder {
    color: var(--contact-text-secondary) !important;
}

.contact-form-container textarea {
    min-height: 150px;
}

.contact-form-container button[type="submit"] {
    background-color: var(--contact-accent) !important;
    color: var(--contact-text-primary) !important;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.contact-form-container button[type="submit"]:hover {
    background-color: var(--contact-hover-bg) !important;
}

.contact-footer {
    margin-top: 30px;
    text-align: center;
}

.contact-footer p {
    margin: 5px 0;
    color: var(--contact-text-secondary);
}

.contact-footer a {
    color: var(--contact-accent) !important;
    text-decoration: none;
}

.contact-footer a:hover {
    color: var(--contact-text-primary) !important;
    text-decoration: underline;
}

.contact-social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
}

.contact-social-links img {
    height: 24px;
    width: auto;
    transition: transform 0.3s ease;
}

.contact-social-links a:hover img {
    transform: scale(1.1);
}
  