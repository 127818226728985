/* Variables */
:root {
  --background: #000;
  --card-bg: #0a0a0a;
  --text-primary: #fff;
  --text-secondary: #a0a0a0;
  --accent: #ff0000;
  --border: #333;
  --hover-bg: #111;
  --total-row-bg: #1a1a1a;
  --input-bg: #1a1a1a;
  --max-width: 1400px;
}

/* Container */
.prices-page {
  background-color: var(--background);
  color: var(--text-primary);
  min-height: 100vh;
  padding: 1.5rem;
  width: 100%;
  overflow-x: auto;
}

.prices-container {
  width: 100%;
  margin: 20px auto;
  padding: 0 15px;
  min-width: 1200px; /* Minimum width to ensure proper table display */
}

.prices-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.prices-title {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 700;
  padding: 20px 0;
  display: block;
  line-height: 1.2;
}

.prices-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
}

.prices-timestamp {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 2rem;
}

/* SZN Valuations Table Card */
.prices-card {
  background-color: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 8px;
  margin-bottom: 4rem;
  width: 100%;
}

.prices-card table {
  width: 100%;
  border-collapse: collapse;
}

.prices-card th,
.prices-card td {
  text-align: center;
  padding: 8px 10px;
  border: 1px solid var(--border);
  color: var(--text-primary);
}

.prices-card thead th {
  font-size: 16px;
  background-color: var(--card-bg);
  color: var(--text-primary);
  position: relative;
  white-space: nowrap;
  padding: 12px 10px;
}

.prices-card tbody td {
  font-size: 14px;
}

.prices-card tbody tr:nth-of-type(even) {
  background-color: var(--card-bg);
}

.prices-card tbody tr:nth-of-type(odd) {
  background-color: var(--background);
}

.prices-table-total {
  background-color: var(--card-bg) !important;
  font-weight: 600;
}

.prices-table-total td {
  border-top: 2px solid var(--border);
}

/* Main Table Styles */
.prices-main-table {
  background-color: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 0 0 8px 8px;
  margin-bottom: 32px;
  border-top: none;
  width: 100%;
}

.prices-main-table table {
  width: 100%;
  border-collapse: collapse;
}

.prices-main-table th,
.prices-main-table td {
  text-align: center;
  padding: 8px 10px;
  border: 1px solid var(--border);
  color: var(--text-primary);
}

.prices-main-table thead th {
  font-size: 16px;
  background-color: var(--card-bg);
  color: var(--text-primary);
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  padding: 12px 10px;
}

.prices-main-table tbody td {
  font-size: 14px;
}

.prices-main-table tbody tr:nth-of-type(even) {
  background-color: var(--card-bg);
}

.prices-main-table tbody tr:nth-of-type(odd) {
  background-color: var(--background);
}

.prices-main-table tbody tr:last-of-type {
  border-bottom: 1px solid var(--border);
}

.prices-main-table .inline {
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: var(--text-primary);
}

/* Header content alignment */
.prices-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

/* Filters Integrated into Main Table */
.prices-filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  background-color: var(--card-bg);
  border-bottom: 1px solid var(--border);
  padding: 1rem;
  align-items: end;
  margin: 0;
  border-radius: 8px 8px 0 0;
}

.prices-filter-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.prices-filter-label {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.prices-filter-select,
.prices-filter-input {
  background-color: var(--input-bg) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--border) !important;
  padding: 8px 12px !important;
  border-radius: 4px !important;
  width: 100% !important;
  font-size: 0.875rem !important;
  line-height: normal !important;
  margin: 0 !important;
}

.prices-filter-select:focus,
.prices-filter-input:focus {
  border-color: var(--accent) !important;
  outline: none !important;
  box-shadow: none !important;
}

.prices-filter-input::placeholder {
  color: var(--text-secondary) !important;
  opacity: 0.7 !important;
}

/* Filters form */
.prices-filters-form {
  margin-bottom: 20px;
  border: 1px solid var(--border);
  border-radius: 8px;
  background-color: var(--card-bg);
  width: 100%;
}

.prices-filters-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 0;
  padding: 20px;
}

.prices-filter-item {
  flex: 1;
  min-width: 200px;
  position: relative;
}

.prices-filter-item label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--text-primary);
}

.prices-filter-item select,
.prices-filter-item input {
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--input-bg);
  color: var(--text-primary);
}

/* Action buttons row */
.prices-action-buttons-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid var(--border);
  background-color: var(--card-bg);
  text-align: center;
}

.prices-records-count {
  flex: 1;
  font-size: 1em;
  color: var(--text-secondary);
  white-space: nowrap;
}

/* Remove unused button styles */
.prices-buttons-group {
  display: none;
}

/* Name Link Styles */
.prices-name-link {
  color: var(--text-primary) !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 4px;
}

.prices-name-link:hover {
  color: var(--text-primary) !important;
}

.prices-chevron-icon {
  width: 14px;
  height: 14px;
  color: var(--accent) !important;
  opacity: 0.7;
  transition: transform 0.2s ease-in-out;
}

.prices-name-link:hover .prices-chevron-icon {
  opacity: 1;
  transform: translateX(2px);
}

/* Remove old icon styles */
.inline-icon, .info-icon {
    display: none;
}

/* Search container and suggestions */
.prices-search-container {
  position: relative;
  width: 100%;
}

.prices-suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--card-bg);
  border: 1px solid var(--border);
  border-radius: 4px;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.prices-suggestions-list li {
  padding: 8px 12px;
  cursor: pointer;
  color: var(--text-primary);
  transition: background-color 0.2s ease;
}

.prices-suggestions-list li:hover {
  background-color: var(--hover-bg);
}

/* Links in table */
.prices-table td a {
  color: var(--text-primary) !important;
  text-decoration: none;
}

.prices-table td a:hover {
  color: var(--accent) !important;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
}

/* Force white borders and text for pagination links */
.pagination a {
  background-color: var(--card-bg);
  border: 1px solid #fff !important;
  color: var(--text-primary) !important;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.875rem;
}

.pagination a:hover {
  background-color: var(--hover-bg);
  border-color: #fff !important;
}

.pagination .active a,
.pagination .selected a {
  background-color: var(--accent) !important;
  border-color: #fff !important;
  color: #fff !important;
}

.pagination a:focus {
  outline: none;
  box-shadow: none;
}

/* Remove old responsive adjustments */
@media (max-width: 1200px) {
  .prices-filters {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .prices-filters {
    display: flex;
    flex-wrap: nowrap;
  }
}

/* Table title */
.prices-table-title {
  color: var(--text-primary);
  margin: 0 0 1.5rem 0;
  font-size: 1.5em;
  text-align: center;
}

/* Icon styles */
.prices-table-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  transition: transform 0.2s ease-in-out;
}

.prices-icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.prices-icon-link:hover .prices-table-icon {
  transform: scale(1.1);
}

/* Action buttons row update */
.prices-action-buttons-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid var(--border);
  background-color: var(--card-bg);
  text-align: center;
}

.prices-records-count {
  flex: 1;
  font-size: 1em;
  color: var(--text-secondary);
  white-space: nowrap;
}

/* Ensure the title stays centered */
.prices-table-title {
  flex: 2;
  text-align: center;
}

@media (max-width: 768px) {
  .prices-action-buttons-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .prices-records-count,
  .prices-table-title {
    flex: none;
    width: 100%;
    text-align: center;
  }
}

/* Info row */
.prices-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.prices-records-count {
  font-size: 1em;
  color: var(--text-secondary);
  white-space: nowrap;
}

.prices-last-updated {
  font-size: 1em;
  color: var(--text-secondary);
  white-space: nowrap;
  text-align: right;
}

/* Remove title styles */
.prices-table-title {
  display: none;
}

/* Table wrapper */
.prices-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Tables */
.prices-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  background-color: var(--card-bg);
  border: 1px solid var(--border);
}

.prices-table th,
.prices-table td {
  text-align: center;
  padding: 8px 10px;
  border: 1px solid var(--border);
  color: var(--text-primary);
}

.prices-table th {
  background-color: var(--card-bg);
  color: var(--text-primary);
  font-weight: bold;
  white-space: nowrap;
}

.prices-table tbody tr:nth-of-type(even) {
  background-color: var(--card-bg);
}

.prices-table tbody tr:nth-of-type(odd) {
  background-color: var(--background);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .prices-container {
    padding: 0 15px;
    min-width: auto;
  }

  .prices-filters-row {
    flex-direction: column;
  }
  
  .prices-filter-item {
    width: 100%;
  }

  .prices-info-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .prices-last-updated {
    text-align: center;
  }

  .prices-table-wrapper {
    overflow-x: auto;
    margin: 0 -15px;
    padding: 0 15px;
    width: calc(100% + 30px);
  }

  .prices-table {
    min-width: 1200px;
  }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
  .prices-table {
    font-size: 12px;
  }

  .prices-filter-item label {
    font-size: 14px;
  }

  .pagination li a {
    padding: 6px 8px;
    font-size: 12px;
  }
}

/* Remove old styles */
.prices-main-table {
  display: none;
}

.prices-action-buttons-row {
  display: none;
}

/* Remove unused accent class */
.prices-title-accent {
    display: none;
}

/* Supply value filter */
.supply-value-filter {
  display: flex;
  gap: 8px;
}

.supply-value-filter .prices-filter-select {
  width: 120px;
  height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--input-bg);
  color: var(--text-primary);
  font-size: 14px;
}

.supply-value-filter .prices-filter-input {
  flex: 1;
  min-width: 180px;
  height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--input-bg);
  color: var(--text-primary);
  font-size: 14px;
}

.supply-value-filter .prices-filter-select:focus {
  outline: none;
  border-color: var(--accent);
}

/* Search buttons */
.prices-filter-buttons {
  display: flex;
  gap: 10px;
  padding: 20px;
  border-top: 1px solid var(--border);
}

.prices-filter-button {
  padding: 8px 16px;
  border: none;
  background-color: var(--accent);
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: opacity 0.2s ease;
}

.prices-filter-button:hover {
  opacity: 0.8;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .supply-value-filter {
    flex-direction: column;
    gap: 10px;
  }
  
  .supply-value-filter .prices-filter-select {
    width: 100%;
  }
}

/* Filter toggle button for mobile */
.prices-filter-toggle {
  display: none;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background-color: var(--card-bg);
  border: 1px solid var(--border);
  color: var(--text-primary);
  font-size: 1.1em;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
}

.prices-filter-toggle .chevron-icon {
  transition: transform 0.3s ease;
}

.prices-filter-toggle .chevron-icon.open {
  transform: rotate(180deg);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .prices-filter-toggle {
    display: flex;
  }

  .prices-filters-form {
    display: none;
    margin-bottom: 20px;
  }

  .prices-filters-form.show {
    display: block;
  }

  /* ... rest of existing mobile styles ... */
}
