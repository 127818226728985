@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  --services-background: #000;
  --services-card-bg: #0a0a0a;
  --services-text-primary: #fff;
  --services-text-secondary: #a0a0a0;
  --services-accent: #ff0000;
  --services-border: #333;
  --services-hover-bg: #111;
  --services-input-bg: #1a1a1a;
}

.services-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 15px;
  background-color: var(--services-background);
  color: var(--services-text-primary);
  font-family: 'Montserrat', sans-serif;
}

.services-container h1 {
  text-align: center;
  color: var(--services-text-primary);
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 700;
  padding: 20px 0;
  display: block;
  line-height: 1.2;
}

.accent-text {
  display: none;
}

.services-intro {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--services-text-secondary);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Montserrat', sans-serif;
}

.services-title {
  text-align: center;
  margin: 3rem 0;
  font-size: 1.8em;
  color: var(--services-text-primary);
  font-weight: 600;
}

.service-card {
  background: var(--services-card-bg);
  border: 1px solid var(--services-border);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  transition: transform 0.2s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}

.service-card:hover {
  transform: translateY(-2px);
  background: var(--services-hover-bg);
}

.service-card h3 {
  color: var(--services-text-primary);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.service-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

.service-card p {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: var(--services-text-secondary);
  font-family: 'Montserrat', sans-serif;
}

.service-card ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 auto 1.5rem;
  text-align: left;
  width: fit-content;
  min-width: 60%;
  font-family: 'Montserrat', sans-serif;
}

.service-card li {
  margin-bottom: 0.5rem;
  position: relative;
  color: var(--services-text-secondary);
  padding-left: 1.5rem;
  font-family: 'Montserrat', sans-serif;
}

.service-card li:before {
  content: "•";
  color: var(--services-accent);
  position: absolute;
  left: 0;
}

.service-link {
  display: inline-block;
  color: var(--services-accent);
  text-decoration: none;
  font-weight: 600;
  margin-top: auto;
  padding-top: 1rem;
}

.service-link:hover {
  text-decoration: underline;
}

.pricing-note {
  color: var(--services-accent);
  font-style: italic;
  margin-top: auto;
  padding-top: 1rem;
}

.support-section {
  text-align: center;
  margin: 3rem 0;
  padding: 2rem;
  background: var(--services-card-bg);
  border: 1px solid var(--services-border);
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
}

.support-section h3 {
  color: var(--services-text-primary);
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.support-section p {
  color: var(--services-text-secondary);
  font-family: 'Montserrat', sans-serif;
}

.contact-button-container {
  text-align: center;
  margin-top: 3rem;
}

.contact-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--services-accent);
  color: var(--services-text-primary);
  text-decoration: none;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

.contact-button:hover {
  background-color: var(--services-hover-bg);
  border: 1px solid var(--services-accent);
}

.nft-links {
  margin-top: 1.5rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.nft-links p {
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.nft-links a {
  color: var(--services-accent);
  text-decoration: none;
  transition: color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}

.nft-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .services-container {
    padding: 1rem;
  }
  
  .service-card {
    padding: 1.5rem;
  }
} 