.meet-artist-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.meet-artist-title {
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.meet-artist-accent {
    display: none;
}

.meet-artist-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.meet-artist-card {
    background: var(--card-bg);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease;
}

.meet-artist-card:hover {
    transform: translateY(-5px);
}

/* Updated styles for artist image */
.meet-artist-image {
    width: 150px;
    height: 150px;
    margin: 0 auto 1rem;
    /* Removed border-radius and border */
    overflow: hidden;
}

.meet-artist-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Changed from cover to contain to preserve aspect ratio */
}
/* End of updated styles */

.meet-artist-name {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.meet-artist-meme {
    color: var(--text-secondary);
    margin-bottom: 1rem;
}

.meet-artist-link {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background: var(--accent);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.meet-artist-link:hover {
    background: var(--accent-dark);
}

.meet-artist-loading {
    text-align: center;
    color: var(--text-primary);
    padding: 2rem;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .meet-artist-grid {
        grid-template-columns: 1fr;
    }
} 