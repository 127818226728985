@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* src/AboutComponent.css */

:root {
    --about-background: #000;
    --about-card-bg: #0a0a0a;
    --about-text-primary: #fff;
    --about-text-secondary: #a0a0a0;
    --about-accent: #ff0000;
    --about-border: #333;
    --about-hover-bg: #111;
    --about-input-bg: #1a1a1a;
}

/* Container */
.about-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: var(--about-background);
    color: var(--about-text-primary);
    font-family: 'Montserrat', sans-serif;
}

/* Headers */
.about-main-header {
    text-align: center;
    color: var(--about-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.about-section {
    margin-bottom: 60px;
    padding: 30px;
    background-color: var(--about-card-bg);
    border: 1px solid var(--about-border);
    border-radius: 10px;
}

.about-section h2 {
    font-size: 2em;
    color: var(--about-accent);
    margin-bottom: 30px;
    font-weight: 600;
}

.about-section h3 {
    font-size: 1.5em;
    color: var(--about-text-primary);
    margin: 30px 0 20px;
    font-weight: 600;
}

.about-section h4 {
    font-size: 1.2em;
    color: var(--about-text-primary);
    margin: 20px 0 15px;
    font-weight: 500;
}

/* Content */
.about-content {
    color: var(--about-text-secondary);
    line-height: 1.8;
}

.about-content p {
    margin: 15px 0;
    font-size: 1.1em;
}

.about-content a {
    color: var(--about-accent);
    text-decoration: none;
    transition: color 0.3s ease;
}

.about-content a:hover {
    color: var(--about-text-primary);
    text-decoration: underline;
}

/* Marketplace Section */
.marketplace-section {
    margin: 30px 0;
}

.marketplace-icons {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 15px;
}

.marketplace-icons img {
    height: 40px;
    width: auto;
    transition: transform 0.3s ease;
}

.marketplace-icons img:hover {
    transform: scale(1.1);
}

/* Social Section */
.social-section {
    margin: 30px 0;
}

.social-section p {
    margin: 10px 0;
}

.discord-link img {
    height: 40px;
    width: auto;
    margin-top: 10px;
    transition: transform 0.3s ease;
}

.discord-link img:hover {
    transform: scale(1.1);
}

/* Subsection */
.subsection {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid var(--about-border);
}

/* Buttons */
.about-buttons {
    margin-top: 30px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

/* Button Styling */
.green-button {
    background-color: var(--about-accent) !important;
    color: var(--about-text-primary) !important;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    margin: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: 'Montserrat', sans-serif;
}

.green-button:hover {
    background-color: var(--about-hover-bg) !important;
    color: var(--about-text-primary) !important;
}

/* Use this class for white, bold, underlined links */
.bold-white-link {
    color: #fff;                /* Ensures the link is white */
    text-decoration: underline; /* Underline the link */
    font-weight: bold;          /* Make the link text bold */
    font-family: 'Montserrat', sans-serif;
}

/* First Tweet Section */
.first-tweet {
    margin: 30px 0;
    text-align: center;
}

.tweet-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 15px;
    border: 1px solid var(--about-border);
    transition: transform 0.3s ease;
}

.tweet-image:hover {
    transform: scale(1.02);
}

/* Contact Section */
.contact-section {
    margin-top: 40px;
    padding: 20px;
    background-color: var(--about-background);
    border-radius: 10px;
    border: 1px solid var(--about-border);
}

.contact-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.contact-link {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    background-color: var(--about-card-bg);
    border: 1px solid var(--about-border);
    border-radius: 8px;
    transition: all 0.3s ease;
    text-decoration: none;
    color: var(--about-text-primary);
}

.contact-link:hover {
    transform: translateY(-2px);
    background-color: var(--about-hover-bg);
    text-decoration: none;
    color: var(--about-text-primary);
}

.contact-link img {
    height: 24px;
    width: auto;
}

.contact-link span {
    font-size: 1em;
    font-weight: 500;
}

/* Responsive adjustments for new sections */
@media screen and (max-width: 768px) {
    .about-container {
        padding: 20px 15px;
    }

    .about-section {
        padding: 20px;
    }

    .about-main-header {
        font-size: 2em;
    }

    .about-section h2 {
        font-size: 1.8em;
    }

    .about-content p {
        font-size: 1em;
    }

    .marketplace-icons {
        flex-wrap: wrap;
    }

    .contact-icons {
        gap: 15px;
    }

    .contact-link {
        padding: 8px 15px;
    }

    .contact-link img {
        height: 20px;
    }

    .contact-link span {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 480px) {
    .about-main-header {
        font-size: 1.8em;
    }

    .about-section h2 {
        font-size: 1.5em;
    }

    .about-section {
        padding: 15px;
    }

    .contact-icons {
        flex-direction: column;
        align-items: stretch;
    }

    .contact-link {
        justify-content: center;
    }
}