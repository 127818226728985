/* TokenComponent.css */

:root {
    --token-background: #000;
    --token-card-bg: #0a0a0a;
    --token-text-primary: #fff;
    --token-text-secondary: #a0a0a0;
    --token-accent: #ff0000;
    --token-border: #333;
    --token-hover-bg: #111;
    --token-input-bg: #1a1a1a;
}

/* General Container */
.token-container {
    width: 100%;
    padding: 20px;
    margin: 0;
    background-color: var(--token-background);
    color: var(--token-text-primary);
}

/* Header Section */
.token-header {
    text-align: center;
    margin-bottom: 30px;
}

.token-title {
    text-align: center;
    color: var(--token-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}

.token-metadata {
    font-size: 1.1em;
    color: var(--token-text-secondary);
    margin: 0;
    padding: 0 20px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.token-owners {
    margin-top: 8px;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.owners-label {
    color: var(--token-text-primary);
}

.owners-count {
    color: var(--token-accent);
    font-weight: 500;
}

.analytics-link {
    color: var(--token-text-primary);
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.analytics-link:hover {
    color: var(--token-text-primary);
}

.analytics-link:hover .chevron-icon {
    opacity: 0.8;
}

.chevron-icon {
    width: 16px !important;
    height: 16px !important;
    color: #ff0000 !important;
    margin-left: 2px;
    stroke-width: 2.5;
}

.tooltip-button,
.tooltip-button:hover,
.tooltip-button:focus,
.tooltip-button::before {
    display: none;
}

/* Remove old styles */
.owners-link,
.owners-text {
    display: none;
}

/* Remove old header styles */
.header-main,
.title-section,
.card-number,
.marketplace-links,
.marketplace-link,
.marketplace-icon,
.metadata-row,
.metadata-item,
.label,
.value,
.separator {
    display: none;
}

/* Metrics Grid Layout */
.metrics-grid {
    display: grid;
    gap: 15px;
    margin-bottom: 30px;
    width: 100%;
}

/* Section Styles */
.metrics-section {
    background-color: var(--token-card-bg);
    border: 1px solid var(--token-border);
    border-radius: 8px;
    overflow: hidden;
}

.section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--token-card-bg);
    cursor: pointer;
    transition: background-color 0.3s ease;
    gap: 8px;
}

.section-header:hover {
    background-color: var(--token-hover-bg);
}

.section-header h2 {
    margin: 0;
    font-size: 0.95em;
    font-weight: 500;
    color: var(--token-accent);
    text-align: center;
}

/* Ensure the chevron icon stays aligned with the centered text */
.section-header svg {
    flex-shrink: 0;
}

/* Metrics Content */
.metrics-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 8px;
    padding: 10px;
    background-color: var(--token-background);
}

.metric-tile {
    background-color: var(--token-card-bg);
    padding: 8px;
    border-radius: 6px;
    border: 1px solid var(--token-border);
    text-align: center;
}

.metric-tile h3 {
    color: var(--token-text-secondary);
    font-size: 0.8em;
    margin: 0 0 4px 0;
    font-weight: normal;
}

.metric-tile p {
    font-size: 0.95em;
    margin: 2px 0;
}

.metric-tile .usd-value {
    color: var(--token-text-secondary);
    font-size: 0.75em;
    margin-top: 1px;
}

/* Animation for collapsible sections */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Tables Container */
.tables-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
    .tables-container {
        grid-template-columns: 1fr 1fr;
    }
}

/* Table Styles */
.table-section {
    margin-bottom: 30px;
    background-color: var(--token-card-bg);
    border: 1px solid var(--token-border);
    border-radius: 8px;
    overflow: hidden;
}

.table-container {
    overflow-x: auto;
    margin: 0;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.data-table {
    width: 100%;
    border-collapse: collapse;
}

.data-table th,
.data-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--token-border);
}

.header-content {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.sort-icon {
    width: 16px;
    height: 16px;
    color: var(--token-text-secondary);
    opacity: 0.5;
    transition: all 0.2s ease;
}

.sort-icon.active {
    opacity: 1;
    color: var(--token-accent);
}

.header-content:hover .sort-icon {
    opacity: 1;
}

.address-link {
    color: var(--token-text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
}

.address-link:hover {
    color: var(--token-accent);
}

.marketplace-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 15px;
    margin: 0;
    gap: 5px;
}

.pagination li a {
    padding: 8px 12px;
    border: 1px solid var(--token-border);
    border-radius: 4px;
    color: var(--token-text-primary);
    background: var(--token-card-bg);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;
}

.pagination li.active a {
    background: var(--token-accent);
    border-color: var(--token-accent);
}

.pagination li a:hover:not(.active) {
    background: var(--token-hover-bg);
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {
    .tables-container {
        grid-template-columns: 1fr;
    }

    .table-section {
        border: none;
        border-radius: 0;
        background-color: transparent;
        margin: 0;
        padding: 0;
    }

    .table-container {
        margin: 0;
        padding: 0;
        overflow-x: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }

    .table-container::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }

    .data-table {
        background-color: var(--token-card-bg);
    }

    .data-table th,
    .data-table td {
        white-space: nowrap;
        padding: 8px 12px;
    }

    .table-section h2 {
        padding: 12px 0;
        border-bottom: none;
        margin: 0;
        justify-content: center;
    }
}

/* Remove old scrollbar styles */
.table-container::-webkit-scrollbar,
.table-container::-webkit-scrollbar-thumb,
.table-container::-webkit-scrollbar-track {
    display: none;
}

/* Responsive Design */
@media screen and (min-width: 1200px) {
    .metrics-content {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 1199px) and (min-width: 769px) {
    .metrics-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .token-header h1 {
        font-size: 1.8em;
    }

    .token-subheader {
        font-size: 0.9em;
    }

    .metrics-content {
        grid-template-columns: 1fr;
    }

    .metric-tile {
        padding: 8px;
    }

    .section-header {
        padding: 8px 10px;
    }

    .section-header h2 {
        font-size: 0.85em;
    }

    .table-container {
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    .token-header h1 {
        font-size: 1.6em;
    }

    .section-header h2 {
        font-size: 0.8em;
    }

    .metric-tile {
        padding: 6px;
    }

    .metric-tile h3 {
        font-size: 0.8em;
    }

    .metric-tile p {
        font-size: 0.9em;
    }

    .metric-tile .usd-value {
        font-size: 0.7em;
    }

    .metrics-content {
        padding: 6px;
        gap: 6px;
    }
}

/* Spinner Styles */
.spinner {
    border: 8px solid var(--token-card-bg) !important;
    border-top: 8px solid var(--token-accent) !important;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 50px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Ensure all elements use border-box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Marketplace Icon Styles */
.marketplace-table-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    filter: invert(1);
}

/* Adjust table cells to accommodate the new icon */
.table-section th,
.table-section td {
    padding: 8px 5px;
}

/* Responsive adjustments for table */
@media screen and (max-width: 768px) {
    .table-section th,
    .table-section td {
        font-size: 12px;
    }

    .marketplace-table-icon {
        width: 20px;
        height: 20px;
    }
}

/* Button Styles */
.btn {
    display: none;
}

/* Center the download buttons */
.download-button {
    display: none;
}

.info-link {
    margin-left: 8px;
    text-decoration: none;
    color: inherit;
}

.info-icon {
    font-size: 14px;
    color: #666;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.info-icon:hover {
    opacity: 1;
}

/* Mobile tooltip */
.tooltip-button::before {
    content: attr(data-tooltip);
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 300px;
    width: auto;
    text-align: center;
    display: none;
    z-index: 100;
    transform: translateY(-100%);
    margin-top: -10px;
}

@media (hover: hover) {
    .tooltip-button:hover::before {
        display: block;
    }
}

/* For mobile devices */
@media (hover: none) {
    .tooltip-button:focus::before {
        display: block;
    }
}

.learn-more-link {
    margin-left: 5px;
    text-decoration: none;
    color: #666;
    font-size: 0.9em;
    opacity: 0.7;
    transition: all 0.2s ease;
}

.learn-more-link:hover {
    opacity: 1;
    color: #ff0000;
    text-decoration: underline;
}

/* Remove old tooltip styles */
.tooltip,
.tooltip:hover {
    display: none;
}

.table-section h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    margin: 0;
    font-size: 1.2em;
    color: var(--token-text-primary);
    border-bottom: 1px solid var(--token-border);
    text-align: center;
}

.sales-header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--token-border);
}

.sales-filters {
    display: flex;
    gap: 10px;
    align-items: center;
}

.sales-filter-select {
    height: 35px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid var(--token-border);
    border-radius: 4px;
    background-color: var(--token-input-bg);
    color: var(--token-text-primary);
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.2s ease;
}

.sales-filter-select:hover {
    border-color: var(--token-accent);
}

.sales-filter-select:focus {
    outline: none;
    border-color: var(--token-accent);
}

.records-count {
    color: var(--token-text-secondary);
    font-size: 0.9em;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .sales-header {
        padding: 8px 12px;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .sales-filters {
        width: 100%;
    }

    .sales-filter-select {
        width: 100%;
        max-width: 200px;
    }

    .records-count {
        font-size: 0.8em;
    }
}

/* Remove duplicate sort indicators added via CSS ::after */
.data-table th::after {
    display: none;
}

.data-table th.sorted-asc::after {
    display: none;
}

.data-table th.sorted-desc::after {
    display: none;
}

/* Optional: Style for clickable table headers */
.data-table th {
    cursor: pointer;
}

/* Remove old styles */
.sort-icon-container,
.sort-icon-container::after,
.sort-icon-container.sorted-asc::after,
.sort-icon-container.sorted-desc::after {
    display: none;
}

/* Remove old token-id class */
.token-id {
    display: none;
}

