/* HomeComponent.css */
:root {
    --home-background: #000;
    --home-card-bg: #0a0a0a;
    --home-text-primary: #fff;
    --home-text-secondary: #a0a0a0;
    --home-accent: #ff0000;
    --home-border: #333;
    --home-hover-bg: #111;
    --card-height: 700px;
}

.home-main {
    width: 100%;
    padding: 40px;
    background-color: var(--home-background);
    color: var(--home-text-primary);
    min-height: calc(100vh - 60px);
}

.home-content {
    max-width: 1400px;
    margin: 0 auto;
}

.home-title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 48px;
    font-weight: 700;
    color: var(--home-text-primary);
    line-height: 1.2;
    display: block;
    padding: 20px 0;
}

.home-title-white {
    color: var(--home-text-primary);
}

.home-title-accent {
    color: var(--home-accent);
}

.title-accent {
    color: var(--home-accent);
}

.home-kpi-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 40px;
}

.home-kpi-box {
    background-color: var(--home-card-bg);
    border: 3px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
    text-decoration: none;
    color: var(--home-text-primary);
    transition: all 0.3s ease;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-kpi-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(255, 0, 0, 0.2);
    border-color: var(--home-accent);
    border-width: 3px;
}

.kpi-content {
    text-align: center;
}

.kpi-content h2 {
    font-size: 32px;
    margin-bottom: 15px;
    color: var(--home-text-primary);
    font-weight: 700;
}

.kpi-content p {
    font-size: 18px;
    color: var(--home-accent);
    margin: 0;
    line-height: 1.4;
    font-weight: 600;
}

/* Featured Tokens Section */
.featured-tokens-section {
    margin-top: 60px;
}

.featured-tokens-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 20px;
}

.featured-token-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 30px;
    transition: all 0.3s ease;
    height: var(--card-height);
    display: flex;
    flex-direction: column;
}

.featured-token-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(255, 0, 0, 0.2);
    border-color: var(--home-accent);
}

.featured-token-title {
    color: var(--home-accent);
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
}

.featured-token-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.featured-token-image-container {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 20px;
}

.featured-token-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: var(--home-background);
}

.token-link {
    text-decoration: none;
    color: inherit;
    display: block;
    text-align: center;
}

.token-link:hover {
    color: var(--home-accent);
}

.featured-token-info {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.token-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.token-details .token-name {
    font-size: 24px;
    margin: 0;
    color: var(--home-text-primary);
}

.token-metadata {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--home-text-secondary);
    font-size: 14px;
}

.token-metadata .separator {
    color: var(--home-border);
}

.price-info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    margin-top: auto;
}

.price-info-three {
    grid-template-columns: repeat(3, 1fr);
}

.price-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 10px;
    background-color: var(--home-background);
    border-radius: 4px;
}

.price-label {
    color: var(--home-text-secondary);
    font-size: 14px;
    text-align: center;
}

.price-value {
    color: var(--home-accent);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.price-value.small {
    font-size: 14px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .price-info,
    .price-info-three {
        grid-template-columns: 1fr;
        gap: 8px;
        margin-top: 15px;
    }

    .price-item {
        padding: 8px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .price-label {
        font-size: 13px;
        margin-right: 8px;
    }

    .price-value {
        font-size: 14px;
        text-align: right;
    }

    .price-value.small {
        font-size: 13px;
    }
}

/* Chart Section */
.chart-section {
    margin-top: 60px;
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 30px;
}

.chart-section-title {
    color: var(--home-text-primary);
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
}

.chart-container {
    width: 100%;
    height: 400px;
    position: relative;
}

/* Supply Enhanced Section */
.supply-enhanced-section {
    margin-top: 60px;
    margin-bottom: 60px;
}

.supply-enhanced-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
    color: var(--home-text-primary);
}

.supply-enhanced-content-wrapper {
    display: block;
    text-decoration: none;
    color: var(--home-text-primary);
    transition: all 0.3s ease;
}

.supply-enhanced-content-wrapper:hover {
    transform: translateY(-5px);
}

.supply-enhanced-content h2 {
    color: var(--home-accent);
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.supply-enhanced-description {
    max-width: 800px;
    margin: 0 auto;
}

.supply-enhanced-description p {
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 25px;
    color: var(--home-text-primary);
}

.supply-enhanced-description ul {
    display: flex;
    justify-content: center;
    gap: 40px;
    list-style: none;
    padding: 0;
    margin: 30px 0;
}

.supply-enhanced-description li {
    font-size: 16px;
    color: var(--home-text-secondary);
    position: relative;
    padding-left: 24px;
}

.supply-enhanced-description li:before {
    content: "•";
    color: var(--home-accent);
    position: absolute;
    left: 0;
    font-size: 24px;
    line-height: 1;
}

.supply-enhanced-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 30px;
    color: var(--home-accent);
    font-size: 18px;
    font-weight: 600;
}

.supply-enhanced-cta i {
    transition: transform 0.3s ease;
}

.supply-enhanced-card:hover .supply-enhanced-cta i {
    transform: translateX(5px);
}

/* Search Section */
.supply-enhanced-search {
    margin-top: 40px;
    border-top: 1px solid var(--home-border);
    padding-top: 30px;
}

.supply-enhanced-search p {
    text-align: center;
    color: var(--home-text-secondary);
    margin-bottom: 20px;
    font-size: 16px;
}

.search-container {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.search-container input {
    width: 100%;
    padding: 15px;
    border: 2px solid var(--home-border);
    border-radius: 8px;
    background-color: var(--home-background);
    color: var(--home-text-primary);
    font-size: 16px;
    transition: all 0.3s ease;
}

.search-container input:focus {
    outline: none;
    border-color: var(--home-accent);
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.1);
}

.search-container input::placeholder {
    color: var(--home-text-secondary);
}

.suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.suggestion-item {
    padding: 12px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: background-color 0.2s ease;
}

.suggestion-item:hover {
    background-color: rgba(255, 0, 0, 0.1);
}

.suggestion-id {
    color: var(--home-accent);
    font-weight: 600;
    min-width: 50px;
}

.suggestion-name {
    color: var(--home-text-primary);
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .home-kpi-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1024px) {
    .featured-tokens-container {
        grid-template-columns: 1fr;
        max-width: 700px;
        margin: 20px auto 0;
    }
}

@media screen and (max-width: 768px) {
    .home-main {
        padding: 20px;
    }

    .home-kpi-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-top: 20px;
    }

    .home-kpi-box {
        padding: 20px;
        min-height: 120px;
        border-width: 2px;
    }

    .kpi-content h2 {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .kpi-content p {
        font-size: 16px;
    }

    .featured-token-card {
        padding: 20px;
        height: auto;
        min-height: calc(var(--card-height) * 0.8);
    }

    .featured-token-image-container {
        height: 300px;
    }

    .chart-container {
        height: 300px;
    }
    
    .chart-section {
        padding: 15px;
        margin-top: 30px;
    }
    
    .chart-section-title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .supply-enhanced-card {
        padding: 25px;
    }

    .supply-enhanced-content h2 {
        font-size: 24px;
    }

    .supply-enhanced-description p {
        font-size: 16px;
    }

    .supply-enhanced-description ul {
        flex-direction: column;
        gap: 15px;
        margin: 20px 0;
    }

    .supply-enhanced-description li {
        font-size: 14px;
    }

    .supply-enhanced-cta {
        font-size: 16px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 600px) {
    .home-kpi-container {
        grid-template-columns: 1fr;
    }

    .home-title {
        font-size: 28px;
        flex-direction: column;
        gap: 5px;
    }

    .home-subtitle {
        font-size: 0.9rem;
        margin-left: 0;
    }

    .featured-token-image-container {
        max-width: 100%;
    }
}

.home-subtitle {
    color: var(--text-secondary);
    font-size: 1.1rem;
    font-weight: 400;
    margin-left: 15px;
}

/* House Artist Section */
.house-artist-section {
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
}

.house-artist-section .section-title {
    color: var(--home-text-primary);
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.artist-link {
    color: var(--home-accent);
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.artist-link:hover {
    opacity: 0.8;
}

.artist-description {
    text-align: center;
    color: var(--home-text-secondary);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.artist-description a {
    color: var(--home-accent);
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.artist-description a:hover {
    opacity: 0.8;
}

.house-artist-section .featured-tokens-container {
    margin-top: 40px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .house-artist-section {
        padding: 25px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .house-artist-section .section-title {
        font-size: 24px;
    }

    .artist-description {
        font-size: 16px;
    }
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .home-kpi-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1024px) {
    .featured-tokens-container {
        grid-template-columns: 1fr;
        max-width: 700px;
        margin: 20px auto 0;
    }
}

@media screen and (max-width: 768px) {
    .home-main {
        padding: 20px;
    }

    .home-kpi-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-top: 20px;
    }

    .home-kpi-box {
        padding: 20px;
        min-height: 120px;
        border-width: 2px;
    }

    .kpi-content h2 {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .kpi-content p {
        font-size: 16px;
    }

    .featured-token-card {
        padding: 20px;
        height: auto;
        min-height: calc(var(--card-height) * 0.8);
    }

    .featured-token-image-container {
        height: 300px;
    }

    .chart-container {
        height: 300px;
    }
    
    .chart-section {
        padding: 15px;
        margin-top: 30px;
    }
    
    .chart-section-title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .supply-enhanced-card {
        padding: 25px;
    }

    .supply-enhanced-content h2 {
        font-size: 24px;
    }

    .supply-enhanced-description p {
        font-size: 16px;
    }

    .supply-enhanced-description ul {
        flex-direction: column;
        gap: 15px;
        margin: 20px 0;
    }

    .supply-enhanced-description li {
        font-size: 14px;
    }

    .supply-enhanced-cta {
        font-size: 16px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 600px) {
    .home-kpi-container {
        grid-template-columns: 1fr;
    }

    .home-title {
        font-size: 28px;
        flex-direction: column;
        gap: 5px;
    }

    .home-subtitle {
        font-size: 0.9rem;
        margin-left: 0;
    }

    .featured-token-image-container {
        max-width: 100%;
    }
}

.home-subtitle {
    color: var(--text-secondary);
    font-size: 1.1rem;
    font-weight: 400;
    margin-left: 15px;
}

/* Holder Profile Section */
.holder-profile-section {
    margin-top: 60px;
    margin-bottom: 60px;
}

.holder-profile-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
}

.holder-profile-card h2 {
    color: var(--home-accent);
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
}

.holder-profile-description {
    max-width: 800px;
    margin: 0 auto 40px;
}

.holder-profile-description p {
    text-align: center;
    color: var(--home-text-primary);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;
}

.holder-profile-description ul {
    list-style: none;
    padding: 0;
    margin: 30px 0;
}

.holder-profile-description li {
    color: var(--home-text-secondary);
    font-size: 16px;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;
}

.holder-profile-description li:before {
    content: "•";
    color: var(--home-accent);
    position: absolute;
    left: 0;
    font-size: 24px;
    line-height: 1;
}

.profile-note {
    color: var(--home-text-secondary) !important;
    font-style: italic;
    font-size: 16px !important;
}

.holder-search {
    max-width: 600px;
    margin: 0 auto;
}

.holder-search p {
    text-align: center;
    color: var(--home-text-secondary);
    margin-bottom: 20px;
    font-size: 16px;
}

.holder-search .search-container {
    display: flex;
    gap: 10px;
}

.holder-search input {
    flex: 1;
    padding: 15px;
    border: 2px solid var(--home-border);
    border-radius: 8px;
    background-color: var(--home-background);
    color: var(--home-text-primary);
    font-size: 16px;
    transition: all 0.3s ease;
}

.holder-search input:focus {
    outline: none;
    border-color: var(--home-accent);
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.1);
}

.holder-search input::placeholder {
    color: var(--home-text-secondary);
}

.view-profile-btn {
    padding: 0 25px;
    background-color: var(--home-accent);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.view-profile-btn:hover {
    opacity: 0.9;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .holder-profile-card {
        padding: 25px;
    }

    .holder-profile-card h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .holder-profile-description p {
        font-size: 16px;
    }

    .holder-profile-description li {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .holder-search .search-container {
        flex-direction: column;
    }

    .holder-search input {
        width: 100%;
    }

    .view-profile-btn {
        width: 100%;
        padding: 15px;
    }
}

/* Price Bands Section */
.price-bands-section {
    margin-top: 60px;
    margin-bottom: 60px;
}

.price-bands-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
}

.price-bands-card h2 {
    color: var(--home-accent);
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.price-bands-description {
    text-align: center;
    color: var(--home-text-secondary);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.price-bands-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.price-band {
    border-top: 1px solid var(--home-border);
    padding-top: 30px;
}

.price-band-title {
    color: var(--home-text-primary);
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.price-band-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.price-bands-cta {
    margin-top: 40px;
    text-align: center;
    border-top: 1px solid var(--home-border);
    padding-top: 30px;
}

.view-all-prices-btn {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 15px 30px;
    background-color: var(--home-accent);
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    transition: opacity 0.3s ease;
}

.view-all-prices-btn:hover {
    opacity: 0.9;
}

.view-all-prices-btn i {
    transition: transform 0.3s ease;
}

.view-all-prices-btn:hover i {
    transform: translateX(5px);
}

/* Mobile adjustments */
@media screen and (max-width: 1024px) {
    .price-band-cards {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 768px) {
    .price-bands-card {
        padding: 25px;
    }

    .price-bands-card h2 {
        font-size: 24px;
    }

    .price-bands-description {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .price-band {
        padding-top: 25px;
    }

    .price-band-title {
        font-size: 20px;
    }

    .view-all-prices-btn {
        width: 100%;
        justify-content: center;
        font-size: 16px;
    }
}

/* Pebbles Section */
.pebbles-section {
    margin-top: 60px;
}

.pebbles-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
    transition: all 0.3s ease;
}

.pebbles-card h2 {
    color: var(--home-accent);
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.pebbles-description {
    color: var(--home-text-secondary);
    text-align: center;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

.pebbles-description p {
    margin-bottom: 20px;
    font-size: 16px;
}

.pebbles-description a {
    color: var(--home-accent);
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.pebbles-description a:hover {
    opacity: 0.8;
}

.pebbles-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 20px;
}

@media screen and (max-width: 1024px) {
    .pebbles-grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 768px) {
    .pebbles-card {
        padding: 20px;
    }

    .pebbles-card h2 {
        font-size: 24px;
    }

    .pebbles-description {
        margin-bottom: 30px;
    }

    .pebbles-description p {
        font-size: 14px;
    }
}

/* Low Supply Section */
.low-supply-section {
    margin-top: 60px;
    margin-bottom: 60px;
}

.low-supply-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
}

.low-supply-card .section-title {
    color: var(--home-accent);
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.low-supply-card .section-description {
    text-align: center;
    color: var(--home-text-secondary);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Mobile adjustments for Low Supply Section */
@media screen and (max-width: 768px) {
    .low-supply-card {
        padding: 20px;
    }

    .low-supply-card .section-title {
        font-size: 24px;
    }

    .low-supply-card .section-description {
        font-size: 16px;
        margin-bottom: 30px;
    }
}

/* PPTDH Section */
.pptdh-section {
    margin-top: 60px;
    margin-bottom: 60px;
}

.pptdh-card {
    background-color: var(--home-card-bg);
    border: 1px solid var(--home-border);
    border-radius: 8px;
    padding: 40px;
}

.pptdh-card .section-title {
    color: var(--home-accent);
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.pptdh-card .section-description {
    text-align: center;
    color: var(--home-text-secondary);
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Mobile adjustments for PPTDH Section */
@media screen and (max-width: 768px) {
    .pptdh-card {
        padding: 20px;
    }

    .pptdh-card .section-title {
        font-size: 24px;
    }

    .pptdh-card .section-description {
        font-size: 16px;
        margin-bottom: 30px;
    }
} 