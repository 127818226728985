/* HodlerAggregatedComponent.css */

:root {
    --ha-background: #000;
    --ha-card-bg: #0a0a0a;
    --ha-text-primary: #fff;
    --ha-text-secondary: #a0a0a0;
    --ha-accent: #ff0000;
    --ha-border: #333;
    --ha-hover-bg: #111;
    --ha-input-bg: #1a1a1a;
}

/* Container */
.hodler-aggregated-container {
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    background-color: var(--ha-background) !important;
    color: var(--ha-text-primary) !important;
}
  
/* Headers */
.hodler-aggregated-container .hodler-aggregated-header {
    text-align: center;
    color: var(--ha-text-primary);
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    padding: 20px 0;
    display: block;
    line-height: 1.2;
}
  
.hodler-aggregated-container .last-updated {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.1em;
    color: var(--ha-text-secondary);
}
  
/* Description */
.hodler-aggregated-container .description {
    margin: 20px auto;
    font-size: 1.1em;
    line-height: 1.5;
    color: var(--ha-text-secondary);
    text-align: center;
    max-width: 800px;
}

.hodler-aggregated-container .description a {
    color: var(--ha-accent);
    text-decoration: none;
    transition: color 0.3s ease;
}

.hodler-aggregated-container .description a:hover {
    text-decoration: underline;
}
  
/* Metrics Card */
.hodler-aggregated-container .metrics-card {
    margin: 20px auto;
    padding: 20px;
    width: 100%;
    border: 1px solid var(--ha-border);
    border-radius: 8px;
    background-color: var(--ha-card-bg);
}
  
.hodler-aggregated-container .card-title {
    font-size: 1.5em;
    color: var(--ha-text-primary);
    margin-bottom: 20px;
    text-align: center;
}
  
/* Table */
.hodler-aggregated-container .centered-table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--ha-card-bg);
}
  
.hodler-aggregated-container .centered-table th,
.hodler-aggregated-container .centered-table td {
    padding: 12px;
    border: 1px solid var(--ha-border);
    color: var(--ha-text-primary);
}

/* Header cell alignment */
.hodler-aggregated-container .centered-table th {
    background-color: var(--ha-card-bg);
    color: var(--ha-text-primary);
    font-weight: bold;
}

/* Left align first column header and data, center align others */
.hodler-aggregated-container .centered-table th:first-child,
.hodler-aggregated-container .centered-table td:first-child {
    text-align: left;
}

.hodler-aggregated-container .centered-table th:not(:first-child),
.hodler-aggregated-container .centered-table td:not(:first-child) {
    text-align: center;
}
  
.hodler-aggregated-container .centered-table tbody tr:nth-of-type(even) {
    background-color: var(--ha-card-bg);
}

.hodler-aggregated-container .centered-table tbody tr:nth-of-type(odd) {
    background-color: var(--ha-background);
}
  
.hodler-aggregated-container .centered-table tbody tr:last-of-type {
    border-bottom: 1px solid var(--ha-border);
}
  
/* Season Metrics */
.hodler-aggregated-container .season-metrics {
    margin: 20px auto;
    width: 100%;
}
  
.hodler-aggregated-container .season-card {
    border: 1px solid var(--ha-border);
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: var(--ha-card-bg);
    width: 100%;
}
  
.hodler-aggregated-container .season-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--ha-card-bg);
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--ha-text-primary);
}
  
.hodler-aggregated-container .season-content {
    padding: 0 20px 20px 20px;
    color: var(--ha-text-primary);
    width: 100%;
}
  
.hodler-aggregated-container .chevron-icon {
    width: 24px;
    height: 24px;
    color: var(--ha-text-primary);
}
  
/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .hodler-aggregated-container {
        padding: 0 15px;
    }

    .hodler-aggregated-container .hodler-aggregated-header {
        font-size: 1.5em;
    }
  
    .hodler-aggregated-container .card-title {
        font-size: 1.3em;
    }

    .hodler-aggregated-container .season-header {
        font-size: 1em;
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .hodler-aggregated-container .centered-table {
        font-size: 12px;
    }

    .hodler-aggregated-container .description {
        font-size: 1em;
    }
}
  